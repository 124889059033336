.Billing{
    text-align: center;
    margin-top: 60px;
}

.BillBox{
    display: flex;
    border: 1px solid rgb(220, 217, 217);
    padding: 10px 20px;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    border-radius: 20px;
    margin-bottom: 40px;
    align-items: center;
}

.BilLeft{
    display: flex;
    justify-content: space-between;   
    align-items: center;
    column-gap: 10px;
}
.BillRemove h4{
    font-size: 16px;
    color: red;
    font-weight: 500;
    cursor: pointer;
}

.cardName{
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: 2px;
    color: #414141;
}
.cardName1 {
    font-size: 16px;
    letter-spacing: 5px;
    color: #414141;
    margin-left: 10px;
    font-weight: 500;
}
.BillImage img{
width: 40px;
}

.mainContent{
    margin-bottom: 70px;
}

.ErrorMessage{
    margin-bottom: 120px;
    color: red;
}
