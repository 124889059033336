@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.main_wrapper {

    padding: 40px 5rem;
    background: #fcfcfc;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;

}

.bradcrumb_bar {
    display: flex;
    list-style: none;
    column-gap: 15px;
    padding-left: 0px;
}

.bradcrumb_bar i {
    padding-right: 12px;
}

.rental_text {
    font-size: 24px;
    font-weight: 600;
    color: black;
    text-transform: capitalize;
    margin-top: 26px;
    margin-bottom: 26px;
}

.filter_wrapper h2 {
    font-size: 24px;
    background: black;
    color: white;
    text-align: center;
    padding: 14px 0px;
    margin: 0;
}

.flters_lists-section {
    width: 20%;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    /* Light border */
    border-top: 4px solid #36D7B7;
    /* Blue border on top */
    border-radius: 50%;
    /* Make it circular */
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    /* Animation effect */
    margin: 20px auto;
    /* Center the loader */
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        /* Starting position */
    }

    100% {
        transform: rotate(360deg);
        /* Ending position */
    }
}

.faq_drawer__content-wrapper {
    font-size: 1.25em;
    line-height: 1.4em;
    max-height: 0px;
    overflow: hidden;
    transition: 0.25s ease-in-out;
}

.faq_drawer__title {
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding: 16px 0 16px 0;
    position: relative;
    margin-bottom: 0;
    transition: all 0.25s ease-out;
    text-transform: uppercase;
}

.faq_drawer__content p {
    font-size: 14px;
    color: #414141;
}

.faq_drawer__title::after {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: " ";
    display: inline-block;
    float: right;
    height: 10px;
    left: 2px;
    position: relative;
    right: 20px;
    top: 2px;
    transform: rotate(135deg);
    transition: 0.35s ease-in-out;
    vertical-align: top;
    width: 10px;
}

.faq_drawer {
    background-color: white;
    border-bottom: 1px solid #EAEAEA;
    padding: 0px 12px;
}

.faq_drawer__trigger:checked+.faq_drawer__title+.faq_drawer__content-wrapper {
    max-height: 350px;
}

.faq_drawer__trigger:checked+.faq_drawer__title::after {
    transform: rotate(-45deg);
    transition: 0.25s ease-in-out;
}


.toggle_switch_button input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.toggle_switch_button label {
    cursor: pointer;
    text-indent: -9999px;
    width: 60px;
    height: 30px;
    background: #a8a8a8;
    display: block;
    border-radius: 100px;
    position: relative;
}

.toggle_switch_button label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 1px;
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.toggle_switch_button input:checked+label {
    background: #F3B47F;
}

.toggle_switch_button input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

.toggle_switch_button label:active:after {
    width: 30px;
}

.room_rent_details {
    display: flex;
    justify-content: space-between;
    column-gap: 22px;
}

.togglle_button_boxes {
    display: flex;
    justify-content: space-between;
}

.room_rent_details_lists {
    width: 60%;
}

.togglle_button_boxes h3 {
    font-size: 14px;
    color: black;
    font-weight: 500;
}

.job_list_search_bar_icon {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 20px;
}

.search_bar_icon_list_bar {
    display: flex;
}

button.btn.btn_base {
    color: white;
    background-color: #FD7E14;
    border-radius: 0px 3px 3px 0px;
    height: 48px;
    display: flex;
    padding: 10px 32px 10px 24px;
    position: relative;
    right: 10px !important;
    align-items: baseline;
    column-gap: 10px;
    text-transform: uppercase;
}

.local_text h2 {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin: 0;
}

.search_bar_icon_list_bar .input_bar input#search {
    border: 2px solid #C8C8C8;
    border-radius: 5px;
    background: transparent;
}

input#search {
    border-radius: 0px;
    height: 48px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    box-shadow: none;
    width: 350px;
}

.arrow_list i {
    color: #FD7E14;
    font-size: 24px;
}

.arrow_list {
    display: flex;
    column-gap: 8px;
    align-items: center;
}

.right_bar {
    /* background-color: white; */
    box-shadow: rgba(152, 157, 167, 0.2) 5px 8px 26px;
    padding: 10px 28px;
    /* border: 1px solid #f3efef; */
    margin-top: 20px;
}

.job_listing_section {
    width: 100%;
    margin-top: 2%;
}

.local_jobs_lists {
    border: 1px solid #c5c5c5;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    position: relative;
    justify-content: space-between;
    background: white;
    margin: 20px 0px;
}

.job_list_image_wraper {
    display: flex;
}

.joblisting_text {
    margin-left: 20px;
}

.joblisting_text h3 {
    align-items: start;
    column-gap: 10px;
    display: flex;
    font-weight: 600;
    font-size: 22px;
    margin-right: 20px;
    color: #333333;
}

.joblisting_text h3 span {
    font-size: 12px;
}

.zoom_app {
    margin-bottom: 15px;
}

.joblisting_text p {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 0px;
}

.join_link {
    margin-bottom: 15px;
}

.join_link p {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
}

.event_text p {
    font-size: 16px;
    font-weight: 400;
    color: #414141;
    width: 100%;
    height: 22px;
    width: 250px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 5px 0 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
}

.view_details_button {
    width: 100%;
}

.job_list_image_wraper img {
    width: 200px;
    height: 200px;
}

.banner_image_wrap img {
    width: 100%;
}

.view_details_button {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 15px;
    column-gap: 12px;
}

.countingtext {
    color: #939393 !important;
    text-decoration: line-through;
}

/* .view_details_button button {
    padding: 10px 30px;
    font-size: 20px;
    font-weight: 500;
    color: #FD7E14;
    border: 1px solid;
    background: transparent;
    border-radius: 8px;
} */
.banner_image_wrap {
    margin-top: 20px;
    text-align: center;
}

.urgent_btn {
    background: #FD1414;
    padding: 4px 32px;
    border: none;
    border-radius: 7px;
    color: white;
    margin-top: 5rem;
}

.local_job_listing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    width: 48%;
}

.local_job_listing i {
    color: #ffffff;
    font-size: 20px;
}

.local_job_listing h4 {
    color: #FD7E14;
    font-size: 20px;
    font-weight: 700;
}

.local_job_listing h6 {
    font-size: 16px;
    color: #333333;
    font-weight: 700;
}

.job_list_image_wraper {
    display: flex;
}

.location_wrap {
    display: flex;
    column-gap: 16px;
    align-items: center;
    /* margin-top: 30px; */
}

.flters_lists_section {
    width: 25%;
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 80px;
    }
}

.Pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Pagination .nextButton {
    margin-left: 10px;
    background-color: #FD1414;
    color: white;
    padding: 4px 10px;
    border: none;
    border-radius: 3px;
}

.prevButton {
    margin-right: 10px;
    background-color: #FD1414;
    color: white;
    padding: 4px 10px;
    border: none;
    border-radius: 3px;
}

.pagination_view h3 {
    font-size: 18px;
}

.job_text {
    margin-top: 20px;
}

.iconPad {
    display: flex;
    justify-content: space-between;
}

.iconPad i {
    margin: 0px 5px;
    color: #1f251f;
}

.myLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.iconPad {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle_switch_button {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.toggle_switch_button h3 {
    margin: 0;
}


.clear_Filters p {
    color: red;
    cursor: pointer;
}

.no_Data {
    color: red;
    font-size: 30px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.input_box input {
    height: 20px !important;
}

.strikethrough_price {
    text-decoration: line-through;
}

.divident {
    display: flex;
    margin-top: 20px;
    margin-left: 20px;

}

.price_drop {
    font-weight: 800;
}

.featured_label {
    left: 25px;
    position: absolute;
    top: 26px;
}

.heart_hovered {
    position: relative;
    bottom: 0px;
}

.view_details_button p {
    color: #47b86a;
}

.accordian_span {
    color: orange;
    float: right;
    cursor: pointer;
}

.display_block {
    display: block;
    color: orange;
    float: right;
    cursor: pointer;
}

.display_none {
    display: none;
    color: orange;
    float: right;
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .main_wrapper {
        padding: 40px 1rem;
        background: #fcfcfc;
    }

    .room_rent_details {
        display: block;
    }

    .flters_lists_section {
        width: 100%;
    }

    .togglle_button_boxes {
        display: block;
        margin-top: 20px;
    }

    .room_rent_details_lists {
        width: 100%;
    }

    .job_list_image_wraper {
        display: block;
    }

    .local_jobs_lists {
        display: block;
    }

    .job_list_image_wraper img {
        width: 100%;
    }

    .local_job_listing {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        column-gap: 20px;
    }

    .joblisting_text {
        margin-left: 0px;
        margin-top: 20px;
    }

    .urgent_btn {
        background: #FD1414;
        padding: 4px 22px;
        border: none;
        border-radius: 7px;
        color: white;
        margin-top: 0rem;
        font-size: 14px;

    }

    .local_job_listing h6 {
        font-size: 14px;
        color: #333333;
        font-weight: 700;
    }
}

.side_banner {
    width: 20%;
}

.side_banner img {
    max-height: 100% !important;
}