#otpForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0 15px 0;
}

.mobileOTPInputSection > div >h4,
.mobileOTPInputSection > div >span,
.emailOTPInputSection >h4{
    text-align: center;
    font-size: 1.1rem;
    padding-bottom: 10px;
    font-weight: 400;
    color: gray;
}

.mobileOTPInputSection > div > span{
    color: orange;
    cursor: pointer;
    font-size: .9rem;
}

.heading_skip_btn{
    display: flex;
    gap: 50px;
    justify-content: space-between;
    padding: 0 20px;
    align-items: flex-start;
}

.mobileOTPInputSection{
    margin-top: 30px;
}

.checkbox_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}


.otpContainer {
    margin: 0% auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.otpInput {
    padding: 0 !important;
    display: flex;
    color: var(--black1);
    align-items: center;
    justify-content: center;
    width: 2.5rem !important;
    height: 2.5rem;
    margin: 0 1rem;
    font-size: 2rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}


.erroResendButtonSection{
    display: flex;
    justify-content: space-between;
    padding: 4px 1rem;
    margin-top: 2px;
}

.errorText{
    font-size: .8rem;
    color: red;
}

.resendText{
    font-size: .9rem;
    color: orange;
    cursor: pointer;
}

.submitBtn{
    margin-top: 20px;
    color: white;
    background-color: orange;
    
}

.skip_text_section{
    display: flex;
    align-items: center;
}

.skip_text_section > p{
    margin-bottom: 0 !important;
    font-size: 1.1rem;
    font-weight: 400;
    color: gray;
    margin-left: 5px;
}

