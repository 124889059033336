.main_showing h1 {
  text-align: center;
  margin: 50px 2px;
}

.boxDiv {
  background-color: white;
  padding: 18px 70px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 15px 4rem;
  height: 140px;
}
.time_lapse p {
  float: right;
}

.read_all {
  position: relative;
  left: 89%;
}

.read_all button {
  background: none;
  border: 1px solid orange;
  padding: 10px;
  color: orange;
}
.paginations{
   display: flex;
   justify-content: center;
   margin: 40px 0px;
}

.paginations button {
  background-color: #f17d3a;
  padding: 1px 7px;
  color: white;
  border: none;
  margin: 0px 10px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
}

.paginations span{
  margin-top: 5px;
  font-size: 16px;
}
@media only screen and (max-width: 767px){
  .read_all {
    position: relative;
    left: 0px;
    text-align: center;
    margin-bottom: 30px;
  }
  .boxDiv {
    background-color: white;
    padding: 18px 70px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 15px 1rem;
    height: 100%;
}
.time_lapse p {
  float: none;
}
}