.time-picker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 250px; /* Adjust width as needed */
}

select {
  padding: 5px;
  margin: 0 5px;
  color: gray;
}
.rc-time-picker-input{
  width: 150px !important;
  font-size: 18px !important;
}
.start_time_text{
padding: 11px;
width: 164px;
border: 1px solid #dfdfdf;}