.help-and-support {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    color: #333;
  }
 
  .help-and-support h1{
font-size: 24px;
font-weight: 600;
  }
   
  .help-and-support h2{
    font-size: 24px;
    font-weight: 600;
      }

  .help-and-support header {
    background-color: #0c0939;
    padding: 10px;
    text-align: center;
  }
  
  .help-and-support main {
    /* max-width: 800px; */
    margin: 0 auto;
    padding: 20px;
    text-align: left;
  }
  
  .help-and-support iframe {
    width: 100%;
    height: 400px;
  }
  
  .help-and-support .store-buttons {
    margin-top: 20px;
    text-align: center;
  }
  
  .help-and-support footer {
    background-color: #fd7e14;
    color: #fff;
    padding: 10px;
    text-align: center;
  }
  
  .help-and-support li {
    text-align: left;
    margin-bottom: 10px;
  }
  
  .help-and-support footer nav a {
    color: #fff;
    text-decoration: none;
  }
  
  .help-and-support footer nav a:hover {
    text-decoration: underline;
  }
  
  .help-and-support .support-link{
    color: blue;
    font-weight: 600;
  }