.MainChat {
    display: flex;
    justify-content: space-between;
}

.Chat_lists{
    width: 30%;
    /* background-color: #F7F7F7; */
    padding: 10px 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    height: 660px;
    overflow: auto;
}
.other_Chat_lists{
    width: 100%;
    /* background-color: #F7F7F7; */
    padding: 10px 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    height: 660px;
    overflow: auto;
}

.Chat_lists h2{
    margin: 10px 20px;
}
.chat_model{
    width: 70%;
}
.list_showing{
    border-bottom: 0.5px solid rgb(216, 216, 216);
    display: flex;
    padding: 10px 3px;
    
}

.image_profilo img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0px 15px;
}
.chat_content h5{
    color: #EC6A37;
    font-size: 18px;
}
.chat_content p{
    margin-bottom: 0px;
    
}

.chat_content .timer_stamp span{
    color: rgb(183, 181, 181);
    font-size: 14px;

}
.chat_content span{
    color: rgb(114 119 119);
}
.chat_content{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.seller_placeholder{
    min-height: 60px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin: 0px 15px;
}

.seller_placeholder p{
    color: rgb(255, 255, 255);
     font-size: 30px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     width: 100%;
     height: 100%;
     align-items: center;
 }