.verify_modal_wrapper{
    max-width: 520px !important;
    border-radius: 10px;
    padding: 30px 40px  50px 40px !important;
    max-height: 600px !important;
}


.login_text {
    font-family: 'Brother 1816';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #8E8E94;
    text-align: center;
    margin-top: 10px;
}

.heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin: 10px auto 10px auto;


}

.forgrt_password_text{
    display: flex;
    justify-content: end;
    margin-top: 5px;
    cursor: pointer;
    color: orange;
}

.remember_text_wrapper{
 display: flex;
 align-items: center;
 justify-content: center;
 gap: 10px;
}

.remember_text_wrapper > input {
    cursor: pointer;
}

.remember_text_wrapper > p {
    margin-bottom: 0px !important;
}

.forget_password_and_rember_me_text{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login_text>span {
    color: rgb(114, 114, 224);
    padding-left: 5px;
    cursor: pointer;
}

.horizontal_line_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.horizontal_line_wrapper>span {
    margin: 0 10px;
}

.horizontal_line {
    border-bottom: 1px solid #E4E7EC;
    ;
    width: 100%;
}

.google_login_btn,
.apple_login_btn {
    width: 100%;
    padding: 10px;
    border: none !important;
    margin-top: 20px !important;
    background-color: var(--white) !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
    border: 1px solid #D0D5DD !important;
}

.apple_login_btn {
    margin-top: -50px !important;
    font-size: 1.2rem !important;

}

.apple_login_btn>svg,
.google_login_btn>svg {
    margin-right: 10px;
}

#submit_btn{
    margin-top: 30px;
}



/* jahsgdahgsfd */

.password_input_wrapper{
    display: flex;
    align-items: center;
    margin-top:  0 !important;
}

.password_input_wrapper > svg{
    margin-left: -30px !important;
    cursor: pointer;
}

#submit_btn{
    margin-top: 30px;
}