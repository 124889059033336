.email_card_wrapper {
    box-shadow: 3px 0px 14px 0px rgba(135, 131, 131, 0.22);
    -webkit-box-shadow: 3px 0px 14px 0px rgba(135, 131, 131, 0.22);
    -moz-box-shadow: 3px 0px 14px 0px rgba(135, 131, 131, 0.22);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 50px;
    border-radius: 5px;
    color: #475467;
    min-width: 250px;
}

.heading {
    font-size: 1.4rem;
    margin: 20px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #101828;
}