#room_rents_form{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.form_button_section{
    margin-top: -15px !important;
}


/* .availability_input_section{
    display: flex;
} */

.availability_input_section_wrapper{
    margin-bottom: -15px !important;
}

.expected_salary_input_wrapper {
    display: grid;
    grid-template-columns: 18% 82%;;
}

.expected_salary_input_wrapper {
    display: grid;
    grid-template-columns: 20% 51% 29%;
}



#salary_input{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

#expected_salary_input_rate {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-left: none !important;
}

#currency_input_field{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-right: none !important;
}

#rent_input{
    /* border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important; */
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

#expected_salary_input_rate {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-left: none !important;
}



.free_regular_ticket_price_input_wrapper,
.regular_price_wrapper {
    display: flex;
    gap: 9px;
    align-items: center;
}

.regular_price_wrapper{
    justify-content: space-between;
}

.free_regular_ticket_price_input_wrapper>label {
    margin-bottom: 0 !important;
}

.free_regular_ticket_price_input {
    width: 14px;
}

.error_Msg{
    color: "red"!important;
    font-size: "12px"!important;
}
