
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.row{
  margin:0px;
}

.App-header {
  /* background-color: #282c34; */
  background-image: url("assest/images/banner.png");
  /* min-height: 90vh; */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}
.space {
  background-color:white;
  min-height: 1px;
}
.header {
  background-color:#282c34;
  min-height: 10vh;
  color:white;
}
.icons{
  display: flex;
  justify-content: space-between;
  width: 15%;
  align-items: center;
  padding-top: 13px;
}
button.btn.btn-warning {
  background-color: orange;
}
.home-icon{
    color: orange;
   
    padding-top: 3px;
}
.main-body {
  padding-top: 20px;
}

.button{
  padding-top: 5px;
  padding-left:0px;
}
button.btn.btn-outline-light {
  padding-top: 10px;
  padding-bottom: 10px;
  /* padding-left: 20px;
  padding-right: 20px; */
}
.body-text {
  color: white;
  padding-top: 10%;
  padding-left: 25%;
}
.body-text p {
  /* margin-left: 10px; */
  font-size: 50px;
  font-weight: 500;
}

.body-text span {
  font-size: 21px;
  padding-left: 50px;
  font-weight: 300;
}
.count-menus ul{
  display: flex;
  list-style: none;
  justify-content: space-between;
  width: 95%;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
}
.count-menus ul li span{
  display: flex;
  list-style: none;
  justify-content: space-between;
  width: 95%;
  align-items: center;
  padding-top: 19px;
  color: orange;
  font-weight: bold;
  font-size: 40px;
}
.menus{
  color:white;
  list-style: none;
}
.body-card{
  /* padding-left: 100px; */
  padding-top: 35px;
  padding-right:5px;
}
.image-card{
  /* padding-left: 100px; */
  padding-top: 45px;
  padding-right:5px;
}
.image-card h4{
  font-size:30px;
}
.image-card p{
  font-size:20px;
  font-weight: 450;
}
.card{
  border-radius:0.75rem;
}
.image{
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
}
.body-card-text{
  font-size: 30px;
  font-weight: 700;
 }
 .blog{
  /* padding-left: 100px; */
  padding-top: 35px;
  padding-right:5px;
 }
 .more{
  color:white;
  padding: 10px 20px 10px 20px;
    margin-left: 7%;
    margin-top:2%;
 }
 .blog p{
  font-size:14px;
 }
 button.btn.btn-view {
  color: orange;
  border-color: orange;
  /* margin-left: 80%; */
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px;
}
.feedback{
  background-color: #F0F0F0;
  min-height: 50vh;
  color: white;
  padding-left: 100px;
  padding-top: 20px;
}
.apps{
  background-color: #F0F0F0;
  min-height: 25vh;
  padding-left: 100px;
  padding-top: 20px;
  margin-top:20px;
}
.footer{
  background-color: #282c34;
  color:white;
  min-height: 25vh;
  padding: 5rem 5rem;

}
.footer h6{
  color:orange;
}
.footer_icon{
    font-weight: 400;
    margin: 20px;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 60%;
}

.apps p{
font-size:20px;
}
.feedback h4{
  font-size:35px;
  color: orange;
}
.feedback p{
  color: black;
  font-size: 21px;
}

button.btn.btn-outline-dark {
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  margin-bottom: 30px;
  font-size: 20px;
}
button.btn.btn-outline-dark:hover{
  background-color: orange!important;
  border: none!important;
}
.menus ul{
  display: flex;
  list-style: none;
  justify-content: space-between;
  width: 95%;
  align-items: center;
  
}
.address{
  padding-top: 17px;
    
}
.App-link {
  color: #61dafb;
}
input#search {
  border-radius: 0px;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
select#exampleFormControlSelect1 {
  border-radius: 3px 0px 0px 3px;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.search-form button {
height: 100%;
width: 100%;
font-size: 1rem;
}
.row.no-gutters {
  width: 60%;
  padding-top: 25px;
  margin-left: 10%;
}
.search-form button svg {
width: 24px;
height: 24px;
}

button.btn.btn-base {
  color: white;
  background-color: orange;
  border-radius: 0px 3px 3px 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.menu-left {
  top: calc(100% + 4px);
  right: 0;
  left: 0!important;
}
.menu{
  left: 0!important;
}
@media screen and (max-width: 767px) {
  .count-menus ul {
    font-size:14px;
  }
  .body-text {
    color: white;
    padding-top: 10%;
    padding-left: 10%;
  }
  .body-text p {
    /* margin-left: 10px; */
    font-size: 25px;
    font-weight: 500;
  }
  .body-text span {
    font-size: 15px;
    padding-left: 50px;
    font-weight: 300;
  }
  .card{
    margin-top: 10px;
  }
}

.arrow_back i{
  position: relative;
  bottom: 7rem;
  left: 5rem;
  background: orange;
  /* padding: 22px; */
  border-radius: 40px;
  color: white;
  font-size: 24px;
  width: 60px;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.billing-wrapper-sec{
  position: relative;
}
.billing-wrapper-sec i{
  position: absolute;
  left: 48px;
  top: 25px;
  background: orange;
  /* padding: 22px; */
  border-radius: 40px;
  color: white;
  font-size: 24px;
  width: 60px;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
body.ReactModal__Body--open{
  overflow: hidden;
}


.Dropdown_dropdown__ZbOaq .Dropdown_button__HvVDU.Dropdown_button-secondary__-1SjD {
  background-color: #ffffff!important;
  color: #000000!important;
  font-size: 14px!important;
}

.Dropdown_dropdown__ZbOaq .Dropdown_menu-left__-aYko{
  left:0
}
.Dropdown_dropdown__ZbOaq .Dropdown_menu-left__-aYko{
  height: 200px;
  overflow-y: scroll;
}
.header_submenu_container__Jl5Rs {
    display: flex;
    flex-direction: column;
    background: white;
}

.Dropdown_dropdown__ZbOaq .Dropdown_menu__ucc97 {
  background-color: white!important;
}


.Dropdown_dropdown__ZbOaq .Dropdown_menu__ucc97 {
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  z-index: 1000;
  flex-direction: column;
  min-width: 48px!important;
  border-radius: 4px;
  background-color: #FFF;
  box-shadow: 0 12px 32px rgba(26, 26, 26, 0.2);
  width: 324px!important;
}
.Submenu_left__HuihD {
  width: 348px!important;
}
.header_submenu__2uOMD {
  left: 50%!important;
}
.Submenu_left__HuihD {
  top: 35px!important;
  right: 98%;    
  
}

.share-buttons svg{ 
  width: 26px;
  height: 26px;
}
.body-card{
  padding-top: 0px;
}
.slick-arrow{
  display: none!important;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-picker-dropdown .ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 4px 0;
  padding: 0;
  overflow-y: hidden;
  text-align: start;
  list-style: none;
  transition: background 0.2s;
  overflow-x: hidden;
  flex-direction: column!important;
}
i.fa-regular.fa-comment-dots:hover{
  color: #FD7E14 !important;
}
i.fa-solid.fa-heart:hover{
  color: #FD7E14 !important;
}
.fa-bell:hover{
  color: #FD7E14 !important;
}