.Main_wrapper_section{
    padding: 30px 7rem;
 }

 
 .image_wrapper_sec{
    display: flex;
    column-gap: 50px;
 }
 .testimonail_wrap h1{
    font-size: 40px;
    font-weight: 600;
    color: black;
    text-transform: capitalize;
 }
 .testimonail_wrap p{
    text-align: 16px;
    font-weight: 400;
    color: #4F4F4F;
    width: 100%;
    margin-top: 10px;
    line-height: 22px;
    margin-bottom: 0px
 }
 .testimonail_wrap span{
    color: #494949;
    font-size: 16px;
    font-weight: 500;
 }
 .rating_view{
    margin-top: 14px;
    display: flex;
    column-gap: 20px;
 }
 .rating_view i{
    font-size: 20px;
    color: #FFCC48;
 }
 .rating_view span{
    font-size: 20px;
    color: black;
 }
 @media only screen and (max-width: 767px) {
   .Main_wrapper_section{
      padding: 30px 1rem;
   }
   .image_wrapper_sec{
      display: block;
   }
   .testimonail_wrap{
      margin-top: 20px;
   }
   .testimonail_wrap h1{
      font-size: 30px;
   }
   .testimonail_wrap p{
      font-size: 14px;
      line-height: 22px;
      width: 100%;
      margin-bottom: 28px;
   }
   .response_img img{
      width: 88%;
   }
 }

 
   .profilo {
      min-height: 60px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
 }

 .profilo p{
   color: rgb(255, 255, 255);
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
}
.form_Date{
   font-weight: 500!important;
 }

