.no_data_slash h1{
color: red;
/* text-align: center; */
}
.no_data_slash {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 50vh;
}