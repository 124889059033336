.image_section>img {
    max-width: 500px;
}

.example_setail_section {
    text-align: center;
    margin-top: 30px;
}


.example_setail_section>h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 24px;
}

.example_setail_section>p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #444444;
}

.example_setail_section>span {
    background: #4A8126;
    border-radius: 2px;
    color: var(--white);
    padding: 10px 50px;
    cursor: pointer;
}