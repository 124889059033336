.containered {
    width: 100%;
    position: absolute;
    top: 165px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0;
    border-radius: 3px;
    background: transparent;
  }
  .containered input {
    display: none;
  }
  .containered input:checked + label {
    background: transparent;
  }
.containered input#tab1:checked ~ .line {
    left: 0%;
  }
  .containered input#tab1:checked ~ .content-container #c1 {
    opacity: 1;
  }
  .containered input#tab2:checked ~ .line {
    left: 18%;
  }
  .containered input#tab2:checked ~ .content-container #c2 {
    opacity: 1;
  }
  .containered input#tab3:checked ~ .line {
    left: 38%;
  }
  .containered input#tab3:checked ~ .content-container #c3 {
    opacity: 1;
  }
 
 
  .containered label {
    display: inline-block;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    width: 18%;
    text-align: center;
    background: transparent;
    color: #555;
    position: relative;
    transition: 0.25s background ease;
    cursor: pointer;
  }
  .containered label::after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    display: block;
    background: #ccc;
    bottom: 0;
    opacity: 0;
    left: 0;
    transition: 0.25s ease;
  }
  .containered label:hover::after {
    opacity: 1;
  }
  .containered .line {
    position: absolute;
    height: 2px;
    background: #f17d3a;
    width: 18%;
    top: 34px;
    left: 0;
    transition: 0.25s ease;
  }
  .containered .content-container {
    background: transparent;
    position: relative;
    height: 100px;
    font-size: 16px;
  }
  .containered .content-container .content {
    position: absolute;
    padding: 10px;
    width: 100%;
    top: 0;
    opacity: 0;
    transition: 0.25s ease;
    color: #333;
  }
  .containered .content-container .content h3 {
    font-weight: 500;
    margin: 0;
    font-size: 18px;
  }
   .containered .content-container .content p {
    margin: 5px 0;
  }
  .containered .content-container .content p, .containered .content-container .content i {
    font-size: 13px;
  }



.button_status.active {
  background-color: #4481dd; /* Blue color for active */
  border-radius: 20px;
    border: none;
    padding: 2px 12px;
    color: white;
    font-size: 14px;
}

.button_status.inactive {
  background-color: #4c4c4c; /* Red color for deleted */
  border-radius: 20px;
    border: none;
    padding: 2px 12px;
    color: white;
    font-size: 14px;
}

.button_status.deleted {
  background-color: #ff0000; /* Red color for deleted */
  border-radius: 20px;
    border: none;
    padding: 2px 12px;
    color: white;
    font-size: 14px;
}

.button_status.draft {
  background-color: #d3b50b; /* Yellow color for draft */
  border-radius: 20px;
    border: none;
    padding: 2px 12px;
    color: white;
    font-size: 14px;
}

.price_amount p {
  color: #47b86a;
}

.pad_left {
  padding-left: 40px;
}

.respost_btn {
  background-color: #F17D3A;
  border: none;
  color: white;
  padding: 5px 22px;
  border-radius: 30px;
}
