#room_rents_form{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.form_button_section{
    margin-top: -15px !important;
}


.price_label_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0 !important;
    margin-bottom: 10px;
}

.free_negotiable_check_box_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.price_heaidng{
    margin-bottom: 15px;
}


.expected_salary_input_wrapper {
    display: grid;
    grid-template-columns: 18% 82%;
}

.inventory_qunatity_warpper {
    display: grid;
    grid-template-columns:73%  27%;
}


#currency_input_field{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-right: none !important;
}

#qunatity_unit_input{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-left: none !important;
}


#rent_input{
    /* border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important; */
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}


#inventory_qunatity_input{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}


 input[type=checkbox]+#asvdhag:before{
    margin-right: 5px !important;
}

input[type=checkbox]:checked+#asvdhag:before{
    margin-right: 5px !important
}