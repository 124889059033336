.heading {
    font-size: 1.5rem;
    text-align: center;
}

.button_wrapper { align-items: end !important;}
#submit_btn_wrapper {
    margin-top: 30px;
    display: block !important;
    width: 100% !important;
}

#submit_btn_wrapper > span {
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    line-height: 24px;
    padding: 10px;
    color: var(--white);
    background-color: var(--orange) !important;
}

#back_button {
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 24px;
    color: var(--dark-blue) !important;
}