.room_rents_modal_wrapper {
    max-width: 600px !important;
    border-radius: 10px;
    padding: 30px 40px 40px 40px !important;
    max-height: 688px !important;
}

.heading {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 28px;
    text-align: center;
    color: #101828;
}

.description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;

    /* Gray / 600 */

    color: #475467;
    margin-bottom: 50px;

}

.job_type_input{
    margin-top: 30px !important;
}

.select_input {
    margin-bottom: 5px !important;
}

.job_type_input_section{
    margin-top: 30px;
}
