.border-box{
    border-bottom: 1px solid#000;
    column-gap: 30px;
}
.nav-item a{
    font-size: 15px;
    color: #000;
    font-weight: 500;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    padding: 5px;
    font-weight: 600;
}
/* .dropdown-menu.show {
    height: 300px;
    overflow-y: scroll;
} */
.dropdown-menu.show{
    padding: 10px 15px;
    margin-top: 15px;
}
.clientimg img{
    width: 100%;
}
.clientimg{
   width: 150px;
}
.user-tabs-sec {
    width: 100%;
    display: block;
}
.tab_details_wrap{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 40px;
    border-radius: 18px;
    padding: 20px;
    position: relative;
    height: 100%;
}
.tab_details_wrap h3{
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
}
.tab_details_wrap p{
    font-size: 17px;
    color: #000;
    font-weight: 500;
    width: 100%;
    margin-bottom: 10px;
}
.room-rent-img {
    width: 100px;
    height: 100px;
}
.tabs_boxes_wrap{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.tab_details_wrap{
    width: 48%;

}
.rating_reiew-wrapper{
    display: flex;
    margin-top: 40px;
    border-bottom: 2px solid #c9c9c9;
}
.rating_reiew_wrapper1{
    display: flex;
    column-gap: 30px;  
}
@media only screen and (max-width: 767px) {
    .nav-item a{
        font-size: 13px;
    }
    .border-box{
        flex-wrap: nowrap;
    }
    .tabs_boxes_wrap{
        display: block;
    }
    .tab_details_wrap{
        width: 100%;
        display: block;
    }
    .tab_details_wrap p{
        font-size: 14px;
    }
}

.urgent_label  button{
    background: #FD1414;
    padding: 4px 12px;
    border: none;
    border-radius: 7px;
    color: white;
}
.div_set {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.complete_div{
    margin-left: 10px;
    width: 100%;
}

.text_div{
    display: flex;
    justify-content: space-between;  
}

.text_right {
    float: right;
}

.shareHeart{
    display: flex;
   justify-content: center;
   align-items: center;
}

.featured_label{
    left: 25px;
    position: absolute;
    top: 26px;
}

.den_div {
    font-size: 16px;
    font-weight: 400;
    color: #414141;
    width: 238px!important;
    height: 65px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 6px 0px 0px!important;
}
.tab_details_wrap img{
    width: 100%;
}

.listed_by p{
    font-size: 13px ;
    margin-top: 30px!important;
  }

  .displeyFlex{
    display: flex;
  }

  .content_profile {
    margin-left: 50px;
    margin-top: 20px;
}
.rentaldetailswrapper{
    display: flex;
}