#submit_btn_wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    height: fit-content;
    padding: 7px 0;
    background: var(--orange);
    border-radius: 8px;
}


#submit_btn_wrapper :hover{
    background-color: var(--orange);
}