.joblisting-header{
   
}
.main-body.joblisting-top-header{
   
}
/* The accordion functionality */
:root {
    --transitionLength: 0.4s;
    --transitionTiming: ease;
    }
    
    .accordion-animation-wrapper {
      display: grid;
      grid-template-rows: 0fr;
      overflow: hidden;
      transition: grid-template-rows var(--transitionLength) var(--transitionTiming);
    }
    
    .accordion-trigger-input:checked ~ .accordion-animation-wrapper {
      grid-template-rows: 1fr;
    }
    
    .accordion-animation {
      min-height: 0;
    }
    
    .accordion-transform-wrapper {
      transform: translateY(-100%);
      visibility: hidden;
      transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s var(--transitionLength) var(--transitionTiming);
    }
    
    .accordion-trigger-input:checked ~ .accordion-animation-wrapper .accordion-transform-wrapper {
      transform: translateY(0);
      visibility: visible;
      transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s linear;
    }
    
    /* Margins inside the container will throw animation height calculations off, better use paddings */
    .accordion-content * {
      margin: 0;
    }
    
    
    /* The styles */
    
 
    
    .flters-lists .accordion {
      width: 25%;
      background: white;
    }
    
    .accordion-item {
      border-top: 2px solid #676767;
      border-bottom: 2px solid #676767;
      margin-bottom: -2px;
      overflow: hidden;
      border-radius: 0px;
    }
    
    .accordion-trigger-input {
      clip: rect(0 0 0 0); 
      clipPath: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap; 
      width: 1px;
    }
    .flters-lists{
        padding: 0rem 5rem 3rem;
        display: flex;
        justify-content: space-between;
    }
    
    .accordion-trigger {
      display: block;
      position: relative;
      padding: 16px;
      transition: box-shadow var(--transitionLength) var(--transitionTiming);
      z-index: 1;
      cursor: pointer;
      font-size: 16px;
      text-transform: uppercase;
      border-bottom: 1px solid #dfdfdf;

    }
    
    
    .accordion-trigger-input:focus-visible ~ .accordion-trigger {
      outline: 2px solid;
      outline-offset: -2px;
    }
    
    .accordion-trigger::after {
      display: block;
      position: absolute;
      right: 16px;
      top: 18px;
      content: '';
      width: 8px;
      height: 8px;
      border: 2px solid #676767;
      border-bottom: transparent;
      border-left: transparent;
      transform: rotate(135deg);
      transition: transform var(--transitionLength) var(--transitionTiming);
    }
    
    .accordion-trigger-input:checked ~ .accordion-trigger::after {
      transform: rotate(135deg) rotateX(180deg) rotateY(180deg) translateY(4px) translateX(-4px);
    }
    
    .accordion-trigger-input:checked ~ .accordion-trigger {
      box-shadow: 0 1px 2px rgba(0,0,0,0.1);
      transition: box-shadow calc(var(--transitionLength) / 4) var(--transitionTiming);
    }
    
    .accordion-content {
      padding: 16px;
      background: #f8f8f8;
    }
    
    .accordion-content h2 {
      padding-bottom: 0.75em;
    }
    
    .accordion-content p {
      padding-bottom: 1.5em;
    }
    
    .accordion-content p:last-of-type {
      padding-bottom: 0;
    }
    
    .accordion-content img {
      max-width: 100%;
    }
    .local-jobs-lists {
      border: 1px solid #c5c5c5;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      position: relative;
      justify-content: space-between;
  }
  .job-list-image-wraper{
  display: flex;
  }
    .job-listing-section{
      width: 100%;
      margin-top :2%;
    }
    .local-job-listing{
      display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    }
   .joblisting-text{
    margin-left: 20px;
   }
   .joblisting-text h3{
    font-size: 22px;
    font-weight: 500;
    color: #333333;
   }
   .joblisting-text p{
    font-size: 16px;
    font-weight: 500;
    color: #333333    ;
    margin-bottom: 0px;
   }
   .joblisting-text span{
    color: #4A60A1    ;
    font-size: 16px;
    font-weight:500 ;
   }
   .translate-img img{
    margin-right: 6px;
   }
   .translate-img p{
    font-size: 16px;
    font-weight: 500;
    color: #333333
   }
    .svgicon-wraper{
      position:absolute;
      top: 35px;
      left: 35px;
    }
    .view-details-button{
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;
    margin-top: 15px;
    }
    .job-list-loacation-icon{
      display: flex;
      align-items: center;
      margin-top: 15px;
    }
    .view-details-button button{
      padding: 10px 30px;
      font-size: 20px;
      font-weight: 500;
      color: #FD7E14;
      border: 1px solid;
      background: transparent;
      border-radius: 8px;

    }
    .jobs-items{
        display: flex;
        justify-content: space-between;
       
    }
    .job-listing-text{
        display: flex;
        justify-content: space-between;
    }
    .jobs-list-items{
        display: flex;
    }
    .translate-img{
        display: flex;
        align-items: center;
        margin-top: 15px;
    }
    .translate-img p{
        margin-bottom: 0px;
    }
    .local-job-listing i{
      color: #ffffff;
      font-size: 20px;
    }
    .local-job-listing h4{
      color: #FD7E14;
      font-size: 20px;
      font-weight: 700;
    }
    .local-job-listing h6{
      font-size: 16px;
      color: #333333;
      font-weight: 700;
  
    }
    .local-job-listing span{
      font-size: 16px;
      color: #333333;
      font-weight: 500;
    }
    .filter-wrapper {
      background: black;
      width: 100%;
      margin-left: 0;
      text-align: center;
      padding-bottom: 12px;
      color: white;
      position: relative;
      top: 0;
     
  }
  .filter-wrapper h2{
    font-size: 22px;
    padding-top: 12px;
    margin-bottom: 0;
  }
  .job-list-search-bar-icon{
    width: 100%;
    border: 1px solid #c9c9c9;
    display: flex;
    justify-content: space-between;
    padding: 28px;
    align-items: center;
    border-radius: 5px;
    
  }
  .search-bar-icon-list-bar{
    display: flex;
  }
  .search-bar-icon-list-bar .input-bar input#search{
    border: 1px solid #BEC8D4;
    border-radius: 5px;
  }
  .search-bar-icon-list-bar p{
    margin-bottom: 0px;
    top: 10px!important;
    position: absolute
    ;
  }
  .flters-lists-section{
    display: flex;
    justify-content: space-between;
    padding:20px 5rem;
    margin-top: 2%;
}
.flters-lists-section .accordion{
width:20%
}
.right-bar{
  width: 70%;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  padding: 28px;
}
.local-text h2{
  font-size: 28px;
    font-weight: 700;
    color: #414141;
    margin-bottom: 0;
}
button.btn.btn-base {
  color: white;
  background-color: #FD7E14;
  border-radius: 0px 3px 3px 0px;
  height: 48px;
  padding: 10px 110px 10px 24px;
  position: relative;
  right: 10px!important;
}

@media only screen and (min-width: 1200px) and (max-width: 1366px){
.flters-lists-section{
  padding: 20px 3rem;
}
.flters-lists-section .accordion {
  width: 18%;
}
.right-bar {
  width: 80%;
}
.local-text h2 {
  font-size: 24px;
}
.search-bar-icon-list-bar p {
font-size: 16px;
}
.view-details-button button {
  padding: 8px 26px;
  font-size: 16px;
  font-weight: 500;
}
.view-details-button {
  width: 72%;
}
}
