.heading{
    font-size: 1.5rem;
    text-align: center;
}

.password_input_wrapper{
    display: flex;
    align-items: center;
}

.password_input_wrapper > svg{
    margin-left: -30px !important;
    cursor: pointer;
}

.button_wrapper{
    align-items: end !important;
}



#submit_btn{
    margin-top: 30px;
    display: block !important;
    width: 100% !important;
}