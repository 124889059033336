.Buysell-banner{
    background-image: url("images/Group 1686552435.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height:100%;
  }
  .heading-section h6 {
    font-size: 18px;
    font-weight: 700;
    color: #121212;
}
.location-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    align-items: center;
}
.heading-section button {
    background: #0096CC;
    padding: 2px 18px;
    font-size: 14px;
}