.main__chatcontent {
    flex-grow: 1;
    /* padding: 20px 40px;   */
    /* max-width: 63%; */
    max-width: 80%;
    border-right: 1px solid #ebe7fb;  
    border-left: 1px solid #ebe7fb;  
    margin: 0px auto;
  }
  .chat_model .main__chatcontent.chat_box_wrap{
    flex-grow: 1;
    /* padding: 20px 40px;   */
    /* max-width: 63%; */
    max-width: 100%;
    border-right: 1px solid #ebe7fb;  
    border-left: 1px solid #ebe7fb;  
    margin: 0px auto;
  }
  .content__header {
    padding-bottom: 15px;
    border-bottom: 1px solid #ebe7fb;
  }
  /* .current-chatting-user {
    display: flex;
    align-items: center;
  } */
  .current-chatting-user p {
    margin: 0;
    font-weight: 600;
    font-size: 30px;
  }
  .content__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 9px;
    border-radius: 4px;
    box-shadow: rgba(149, 157, 165, 0.2) 12px 7px 24px;
}
  .settings .btn-nobg {
    color: #000;
  }
  .content__body {
    /* max-height: calc(100vh - calc(100vh / 2)); */
    height: 480px;
    overflow: auto;
  }
  .chat__item {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 15px;
    transition: all 0.3s ease;
    transform: scale(0);
    transform-origin: right;
    animation-name: showIn;
    animation-duration: 0.2s; /* or: Xms */
    animation-iteration-count: 1;
    animation-direction: normal; /* or: normal */
    animation-timing-function: cubic-bezier(
      0.88,
      0.19,
      0.37,
      1.11
    ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
    animation-fill-mode: both; /* or: backwards, both, none */
    animation-delay: 0.2s; /* or: Xms */
  }
  @keyframes showIn {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }  
  .chat__item .avatar {
    margin-right: 0px;
    margin-left: 20px;
    background: #fff;
    padding: 1px;
  }

  
  .chat__item__content {
    border-radius: 10px 10px 0 10px;
    max-width: 50%;
    min-width: 215px;
  }
  .chat__item__content .chat__meta {
    justify-content: space-between;
    display: flex;
    margin-top: 10px;
  }
  .chat__item__content .chat__meta span {
    font-size: 14px;
    color: #999797;
    user-select: none;
  }
  .chat__item .me {
    border-radius: 8px;
background-color: #ec691c;
color: #fff;
padding: 15px;
  }
  .delete_img{
    filter: invert();
  }

  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }

  .image-previews {
    display: 'flex';
    flex-direction: 'column';
    position: 'relative',
  }
  .cancel-icon{
    position: 'absolute';
    top: '5px';
    right: '5px';
    cursor: 'pointer';
    background: '#ffffff';
    border-radius: '50%';
    padding: '3px';
  }
  

  .chat__item.image{
    border-radius: 8px;
/* background-color: #ec691c; */
color: #fff;
padding: 15px;
  }
  
  
  .chat__item.other {
    flex-direction: row-reverse;
    transform-origin: left;
  }
  .chat__item .other {
    padding: 15px;
    background-color: #eeeeee;
    color: #000;
    border-radius: 10px 10px 10px 0;
    max-width: 100%;
  }
  .chat__item.other .avatar {
    margin-right: 20px;
    margin-left: 0px;
  }
  .chat__item.other .chat__item__content .chat__meta span {
    color: #999797;
  }
  .content__footer {
    padding-top: 30px;
  }
  .sendNewMessage {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px;
  }
  .sendNewMessage button {
    width: 49px;
    height: 49px;
    background-color: #ecefff;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    color: #4665ff;
    padding: 0;
    /* border-radius: 5px; */
    line-height: 36px;
    transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}
  .sendNewMessage button:hover {
    transform: scale(1.2);
  }
  .sendNewMessage button i {
    display: block;
  }
  .sendNewMessage input {
    flex-grow: 1;
    padding: 0 15px;
    background-color: transparent;
    border: none;
    outline: none;
    /* margin: 0px 10pxg; */
  }
  #sendMsgBtn {
    background-color: #F17D3A;
    color: #fff;
  }

  .profile_images img{
    width: 80px;
    border-radius: 50%;
    height: 80px;
    margin: 0px 10px;
  }
  .profile_img_header{
    display: flex ;
    cursor: pointer;
  }

  .chat__items{
    padding: 20px 30px;
  }

  .deletebtn {
    color: rgb(224, 13, 13);
    position: relative;
    bottom: 40px;
    right: 20px;
    color: white;
  }


  .profile_section .online {
    color: green; 
    margin-top: 6px;
  }
  
  .profile_section .offline {
    color: grey; 
    margin-top: 6px;
  }