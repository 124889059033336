.image_upload_wrapper {
    display: flex;
    align-items: center;
}

.hero_image_div:hover .close_icon{
    display: block;
}

.hero_image_div:hover{
   opacity: 1;
}

.image_wrapper_section{
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
}

.files_container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .hero_image_div {
    transition: transform 0.2s ease;
    cursor: move;
  }
  
  .hero_image_div:hover {
    transform: scale(1.05);
  }
  


.image_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;

    background: #F2F4F7;
    border-radius: 200px;

    border-radius: 50%;
}

.image_upload_btn {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid red;
    padding: 10px 16px;
    gap: 8px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    cursor: pointer;
}

.image_upload_btn>p {
    margin-bottom: 0 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.close_icon{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: none;
    cursor: pointer;
}

.upload_imgae_btn_wrapper > p {
    margin-bottom: 0 !important;
    font-size: .7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2px;
}

.upload_imgae_btn_wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
}