#room_rents_form{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.form_button_section{
    margin-top: -15px !important;
}


.label_addbtn_section{
    display: flex;
    align-items: baseline;
    gap: 8px;
}

.add_btn_text {
    background-color: var(--orange);
    padding: 0px 5px;
    border-radius: 50px;
    font-size: 0px;
    color: var(--white);
    display: flex;
    box-shadow: none;
    cursor: pointer;
    margin-bottom: 20px;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .experience_input_field_wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
} */

.experience_section_wrapper{
    background-color:var(--light-gray1);
    padding: 15px 20px 15px 20px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 10px;

}

#closed_experience_section{
    padding-bottom: 0px !important;
    background-color:var(--light-gray);
}

.experience_input_field_wrapper > input{
    margin-bottom: 10px;
}

/* experience date */
.experience_date_input_wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.delete_edit_setion{
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    /* position: absolute;
    top: 5%;
    right: 3%; */
}

.experience_count{
    margin: 10px 0 0px 5px !important;
    font-size: 1rem;
}

.delete_edit_setion > span{
    cursor: pointer;
}

.delete_edit_icon_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.delete_edit_icon_wrapper > span{
    cursor: pointer;
}

.expected_salary_input_wrapper {
    display: grid;
    grid-template-columns: 20% 51% 29%;
}

#currency_input_field{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-right: none !important;
}

#salary_input{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

#expected_salary_input_rate {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-left: none !important;
}
.delete_edit_icon_wrapper i{
    color: #f17d3a;
    font-size: 24px;
    position: relative;
    top: 4px;

}


.hidden {
    display: none;
  }
  