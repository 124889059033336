.example_modal {
    max-width: 600px !important;
    border-radius: 10px;
    padding: 30px 40px 40px 40px !important;
    max-height: 688px !important;
}

.ads_plan_wrapper {
    margin-top: -20px !important;
}

#selected_plan {
    border: 2px solid #FD7E14;
}

.loader_overlay {
    position: fixed;
    top: 0px;
    /* left: 50px; */
    right: 0px;
    bottom: 0px;
    padding-left: 100px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.select_duration_heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 19px;

    /* text black */

    color: #1C1C1C;
    margin-bottom: 10px;
}

.plan_detail_section_wrapper {
    display: flex;
    gap: 20px;
    overflow-y: scroll;
    margin-bottom: 20px;
}

.plan_detail_section {
    min-width: 230px !important;
    width: 230px;
    border: 2px solid #D0D5DD;
    padding: 17px;
    border-radius: 10px;
    cursor: pointer;
}

.plan_detail_section>p {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 0 !important;
}

.plan_amount_title_text_section,
.addon_amount_title_text_section {
    display: flex;
    justify-content: space-between;
}

.addon_amount_title_text_section>svg {
    margin-top: 5px;
}


.plan_amount_title_text_section>svg {
    margin-top: -5px;
    cursor: pointer;
}

.plan_amount_title_text>h3 {
    /* Display xs / Semibold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 32px;
    margin-bottom: 0 !important;
    /* identical to box height, or 133% */


    /* Gray / 900 */

    color: #101828;

}

.plan_amount_title_text>p {
    /* Text md / Semibold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;

    color: #101828;

}

.duration_text {
    font-size: .9rem;
}

.plan_detail_section>p {
    /* Text md / Regular */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #475467;

}


/* addon section */
.addon_section {
    padding: 20px;
    background-color: var(--light-gray3);
    margin-bottom: 10px;
    border-radius: 5px;

}

#active_card {
    border: 2px solid #FD7E14;
    box-shadow: 0px 0.909091px 1.81818px rgba(16, 24, 40, 0.05);
    border-radius: 10.9091px;
}

.active_card {
    border: 2px solid #FD7E14 !important;
    box-shadow: 0px 0.909091px 1.81818px rgba(16, 24, 40, 0.05);
}

.addon_name_text {
    margin-bottom: 10px !important;
    font-size: 1.1rem;
}

.addon_price_section_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.addon_price_card_section {
    background-color: var(--white);
    border-radius: 5px;
    padding: 10px 12px;
    width: 144px;
    max-width: 144px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 0px !important;
}


.addon_price_duration_wrapper {
    margin-bottom: 0px !important;
}

.addon_price_duration_text {
    margin-left: 5px;
    font-size: .8rem;
}



.featured_price_section {
    margin: 30px 0;
}

.featured_price_section>h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    /* or 162% */


    /* Gray / 900 */

    color: #101828;
}

.featured_price_text_wrapper {
    padding: 10px 18px;
    align-items: center;
    border: 2px solid #D0D5DD;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}


.featured_price_section>input {
    height: 59.09px;
    background: #FFFFFF;
    box-shadow: 0px 0.909091px 1.81818px rgba(16, 24, 40, 0.05);
    border-radius: 10.9091px;
}


.price_calculation_section {
    display: flex;
    justify-content: space-between;
    background: #f8f9fa;
    margin-top: 20px;
    border-radius: 10px;
    padding: 15px 25px;
}

.price_calculation_heading,
.price_calculation_amount {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.price_calculation_heading>span {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 22px;
    /* identical to box height, or 150% */


    /* Black color */

    color: #121212;
}


.price_calculation_amount>span {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14.5455px;
    line-height: 22px;
    /* identical to box height, or 150% */


    /* Gray / 600 */

    color: #475467;

}

.amount_text {
    color: var(--dark-orange2) !important;
}

#selected {
    border: 2px solid #FD7E14 !important;

}

.active {
    background-color: red !important;
}

/* updated css */

.expire_text_section {
    display: flex;
    justify-content: space-between;
    border: 2px solid var(--dark-orange2);
    box-shadow: 0px .9px 1px rgba(16, 24, 40, 0.05);
    border-radius: 10px;
    padding: 30px;
    margin-top: 10px;
}

#expire_text_section {
    justify-content: center !important;
}

.ad-page-main #back_button {
    display: none !important;
}

.expire_text {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    line-height: 13px;


    color: var(--dark-orange2);
}

.ads_expire_info_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;

    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #FD7E14;
}

.price_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.sub_heading_and_selectall_wrapper {
    margin: 35px 0 25px 0;
}

.sub_heading_and_selectall_wrapper>h4 {
    margin-bottom: 0 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
}

.sub_heading_and_selectall_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sub_heading_and_selectall_section>span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
}

.plan_card_wrapper {
    background: #f8f9fa;
    border: 1px solid #A9A8B3;
    box-shadow: 0px 0.909091px 1.81818px rgba(16, 24, 40, 0.05);
    border-radius: 10.9091px;
    padding: 15px 25px;
    margin-top: 20px;
}

.select_all_label {
    display: flex;
    margin-right: -13px;
    align-items: center;
}

.select_all_label>span {
    color: var(--dark-orange2);
    font-size: 1.1rem;
    font-family: "Inter";
    font-weight: 500;
    line-height: 29.091px;
}

.plan_card_wrapper {
    display: grid;
    grid-template-columns: 70% 25%;
    place-items: center;
    gap: 5%;
}

.check_plan_heading_wrapper {
    width: 100%;
}

.check_box_wrapper {
    display: flex;
    align-items: center;
    margin-left: -10px;
}

#select_plan_input {
    height: 27px !important;
    padding: 4px 2px !important;
    font-size: .8rem !important;
    width: auto !important;
}

.plan_price_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 32px;
    color: #101828;
    margin-bottom: -5px;
}

.plan_heading_text {
    background: #FFDA3E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 29px;
    padding: 0px 10px;
    color: var(--white);
    margin-bottom: 0px;
    margin-left:10px
}
.plans_checkbox{
    /* display: "block";
    appearance: "checkbox", */
    background-color: "orange" !important;
    color: "white";
    width: "20px";
    height: "20px";
    border: "2px solid orange"
    /* marginRight: "5px", */
}
.oldPrice{
    text-decoration-line: line-through;
    font-size: 14px;
    font-weight: 600;
    color: gray;

}
.newPrice{
    color: green;
    font-size: 14px;
    font-weight: 600;
}
.plan_description_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    line-height: 20px;
}

.website_text_input_field {
    height: 40px !important;
    font-size: .9rem !important;
    border: 2px solid var(--dark-orange2) !important;
    margin-bottom : 10px
}

.website_text_input_field::placeholder {
    font-size: .9rem !important;
}

.view_example_text {
    color: #4482FF;
    cursor: pointer;
}

.error_message {
    font-size: 13px;
}

.error_drop {
    text-decoration: line-through;
    color: rgb(70, 67, 67);
    font-size: 14px;
    font-weight: 500;
}

.actual_drop_price {
    color: #47b86a;
    font-size: 14px;
    font-weight: 500;
}

.invalid {
    border: 1px solid red;
}

#adsPlans_select_plan_input__GvVe9 {
    font-size: .8rem !important;
    height: 27px !important;
    padding: 4px 2px !important;
    width: auto !important;
}