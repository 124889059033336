#room_rents_form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.form_button_section {
    margin-top: -15px !important;
}

.addbtn_label_section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 20px;
}

.add_btn_text {
    background-color: var(--orange);
    padding: 7px 20px;
    border-radius: 30px;
    font-size: .9rem;
    color: var(--white);
    margin-bottom: 0px !important;
    box-shadow: 6px 5px 5px -2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 6px 5px 5px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 5px 5px -2px rgba(0, 0, 0, 0.75);
    cursor: pointer;
    /* display: none; */
}

.platform_link_input_field{
    display: grid;
    grid-template-columns: 100% 0%;
    align-items: center;
    margin-top: 20px;
}

.remove_platform_link_input_btn{

    cursor: pointer;
    margin-left: -42px;
    background: white;
    padding-left: 10px;
}

.other_platform_section_wrapper{
    margin-top: 20px;
}

.other_platform_input_field_wrapper{
    background-color: var(--light-gray1);
    padding:40px 20px 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
}

.close_icons{
    position: absolute;
    right: 10px;
    top: 6px;
}

.other_field_input > input{
    margin-bottom: 10px;
}


.regular_price_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.event_date_time_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

/* date pictr and time picckr css */

.date_picter_wrapper,
.time_picter_wrapper {
    display: block !important;
    height: 50px;
}


.free_regular_ticket_price_input_wrapper,
.select_event_platform_link_checkbox,
.live_event_checkbox_input_wrapper {
    display: flex;
    gap: 9px;
    align-items: center;
    margin-bottom: 15px;
}

.free_regular_ticket_price_input_wrapper>label {
    margin-bottom: 0 !important;
}


.free_regular_ticket_price_input {
    width: 14px;
}

.live_event_link_input_wrapper {
    margin-bottom: 20px !important;
}

.live_event_input_wrapper {
    margin-bottom: -15px !important;
}


.expected_salary_input_wrapper {
    display: grid;
    grid-template-columns: 18% 82%;
}

#currency_input_field {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-right: none !important;
}

#price_input_field {
    /* border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important; */
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}