.joblisting_header{
    background-color: black;
    color: white;
    padding: 20px 5rem;
}
.top_header{
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.address{
    padding-left: 0px;
  }
  .icons{
    display: flex;
    justify-content: space-between;
    width: 14%;
    align-items: center;
    padding: 0px;
  }
  .post_btn{
    background: #FD7E14;
    padding: 5px 25px;
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    border: none;
  }
  .joblisting_top_header{
    background-color: black;
  }
  .navbar_list{
    padding: 20px 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Logo_icon img{
    width: 14rem;
  }
  .menus{
    color:white;
    list-style: none;
  }
  .menus ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    column-gap: 60px;
    margin-bottom: 0;
}
.profile_list {
    display: flex;
    align-items: center;
    column-gap: 25px;
}
.profile_name{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.profile_list i{
font-size: 22px;
color: white;
}
.profile_name h3{
    font-size: 20px;
    color: white;
}
@media only screen and (min-width: 1200px) and (max-width: 1366px){
  .navbar_list {
    padding: 20px 3rem;
  }
.Logo_icon img {
  width: 12rem;
}
.joblisting_header{
  padding: 20px 3rem;
}
.menus ul {
  column-gap: 24px;
}
.flters-lists-section{
  padding: 20px 3rem;
}
}