/* .container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    padding: 24px 12px 64px;
    overflow-y: auto;
} */

.item {
    display: flex;
    padding: 5px;
    border-radius: 5px !important;
    position: relative;
    max-width: fit-content;
    flex-basis: 100%;
    border-radius: 2px;
    flex-direction: column;
}

.inner-item {
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.SortableList {
    position: relative;
    z-index: 0;
    background-color: #F3F3F3;
    border: 1px solid red;
    border-radius: 3px;
    outline: none;

    width: fit-content;
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #E0E0E0;
    list-style: none;
    padding: 0;
}

.SortableItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 60px;
    /* padding: 0 20px; */
    background-color: red;
    border-bottom: 1px solid #EFEFEF;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 500;
}

.SortableHelper {
    box-shadow: 3px 3px 5px 2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 3px 3px 5px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 3px 5px 2px rgba(0,0,0,0.75);
    /* background-color: rgba(255, 255, 255, 0.8); */
    cursor: row-resize;
}