/*Home-page*/
.footer-wraper{
  margin-bottom: 20px;
  width: 22%;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
  }
.top-header {
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.post-btn{
  background: #FD7E14;
  padding: 7px 25px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  border:none;
  height: 48px;
  width: 140px;
}
.dropdown.button.button-secondary {
  background-color: #ffffff!important;
  color: #172B4D!important;
  font-size: 15px!important;
}
  .navbar_list{
    padding: 20px 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .home-icon img{
    width: 14rem;
  }
  .row{
    margin:0px;
  }
  .fav{
    padding-top: 10px;
  }
  .card-text {
    padding-top: 10px;
}
.detail{
  padding-top:10px;
}
button.btn.btn-detailbtn {
  color: orange;
  border-color: orange;
  border-radius: 10px;
}
  .App-header {
    /* background-color: #282c34; */
    background-image: url("images/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
   
   
  }
#search-form{
  padding: 20px 5rem 5rem;
}
.location-bar img{
  width: 16px;
  height: 16px; 
  position: absolute;
  left: 8px;
  
}
/* SkeletonLoader.css */
.skeleton-loader {
  width: 100%;
  padding: 20px;
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-header {
  width: 100%;
  height: 20px;
}

.skeleton-text {
  width: 100%;
  height: 15px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 200% 100%;
}

.location-bar .location-bar-icon-dropdown{
  left: 65%;
    cursor: pointer;
}
.search-bar{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}
.location-bar{
  display: flex;
position: relative;
align-items: center;

}

  .header {
    background-color: transparent;
    color: white;
    padding: 20px 5rem;
    border-bottom: 1px solid #DBDFEC;
}
  .icons{
    display: flex;
    justify-content: space-between;
    width: 14%;
    align-items: center;
    padding: 0px;
  
  }
  button.btn.btn-warning {
    background-color: orange;
  }
  .home-icon{
      color: orange;
      
  }
.top-banner{
  text-align: center;
  margin-top: 8rem;
}
.top-banner h1{
  font-size: 54px;
  color: white;
  font-weight: 600;
  line-height: 70px;
}
.top-banner p{
  font-size: 18px;
  color: white;
  margin: 0 auto;
  width: 34%;
  padding-top: 10px;
  font-weight: 400;
}
  button.btn.btn-outline-light {
    padding-top: 10px;
    padding-bottom: 10px;
    /* padding-left: 20px;
    padding-right: 20px; */
  }
  .body-text {
    color: white;
    padding-top: 10%;
  }
  .body-text p {
    /* margin-left: 10px; */
    font-size: 50px;
    font-weight: 500;
  }
  
  .body-text span {
    font-size: 21px;
    padding-left: 50px;
    font-weight: 300;
  }
  .count-menus ul{
    display: flex;
    list-style: none;
    justify-content: space-between;
    width: 95%;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
  }
  .count-menus ul li span{
    display: flex;
    list-style: none;
    justify-content: space-between;
    width: 95%;
    align-items: center;
    padding-top: 19px;
    color: orange;
    font-weight: bold;
    font-size: 40px;
  }
  .menus{
    color:white;
    list-style: none;
  }
  .body-card{
    display: flex;
    justify-content: space-between;
    
    margin-top: 3%;
  }
  .cards-items{
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
  }
  .cards-items .card-image{
    width: 24%;
  }
  .image-card{
    /* padding-left: 100px; */
    padding-top: 45px;
    padding-right:5px;
  }
  .image-card h4{
    font-size:30px;
  }
  .image-card p{
    font-size:20px;
    font-weight: 450;
  }
  .cardimage.job-category-card{
    height:250px;
    width:100%;
    border-radius:0.75rem 0.75rem 0.75rem 0.75rem;
    background-image: url("images/Group 1686552424.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .cardimage{
    height:200px;
    width:100%;
    border-radius:0.75rem 0.75rem 0rem 0rem;
    /* background-image: url("images/Rectangle 22928 (1).png"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .cardimage img{
    border-bottom: 2px solid #e7e7e7;
  }

  .cardimage.eventlist-card{
    height:250px;
    width:100%;
    border-radius:0.75rem 0.75rem 0.75rem 0.75rem;
    background-image: url("images/Rectangle 22926.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .cardimage.buyandsell-card{
    height:250px;
    width:100%;
    border-radius:0.75rem 0.75rem 0.75rem 0.75rem;
    background-image: url("images/Group 1686552033 (1).png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
 
 
  .card {
    background: white;
    border: none;
    box-shadow: rgb(100 100 111 / 20%) 4px 4px 13px 5px;
    border-radius: 18px;
    margin: 30px 24px;
}
  .card-text h6{
    
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
  }
  .fav-icon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
    position: relative;
    bottom: 107%;
  }
  .home-icons {
    display: flex;
    justify-content: space-between;
    width: 65%;
    margin-top: 16px;
}
  .home-icons div{
    display: flex;
  }
  .home-icons img{
    width: 26px;
    height: 26px;
  }
  .home-icons p{
    font-size: 18px;
    color: #6D737A;
    font-weight: 500;
    margin-left: 10px;
  }
  .location.location-icon img{
    width: 20px;
    height: 20px;
  }
  .location.location-icon{
    display: flex;
    align-items: center;
  }
  .location.location-icon p{
    font-size: 17px;
    font-weight: 500;
    color: black;
    margin-left: 10px;
    margin-bottom: 0;
  }
  .time{
    display: flex;
    align-items: center;
  }
  .time p{
    font-size: 17px;
    font-weight: 700;
    color: #FD7E14    ;
    margin-left: 10px;
    margin-bottom: 0;
  }
  .time i{
    border: 1px solid;
    padding: 5px;
    border-radius: 100px;
    width: 26px;
    height: 26px;
    font-size: 16px;
    text-align: center;
    color: #FD7E14;
  }
  .detail-box{
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    margin-bottom: 20px;
    width: 100%;
  
    align-items: center;
  }
  .eye-icon span {
    position: relative;
    color: #6D737A;
    font-size: 13px;
    font-weight: 500;
    padding: 8px 8px;
    top: -8px;
}

  .eye-icon {
    /* display: flex;
    align-items: center; */
  
  }
  .view-orange-btn{
    color: #FD7E14;
    border: 1px solid #FD7E14;
    width: 220px;
    height: 48px;
    background: transparent;
    font-size: 20px;
    font-weight: 500;
    border-radius: 10px;

  }
  .image{
      width: 100%;
      height: 200px;
      border-radius: 10px 10px 0px 0px;
  }
  .heading-section{
    display: flex;
    justify-content: space-between;
    margin-top: 7%; 
    align-items: center;
  }
  .heading-section h6{
    font-size: 24px;
    font-weight: 700;
    color: #121212;
    width: 100%;
    height: 21px;
    width: 150px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 5px 0 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    margin: 6px 3px;

  }
  .heading-section button{
    background: #0096CC;
    padding: 5px 18px;
    border: none;
    border-radius: 50px;
    color: white;
  }
  .job-text{
    font-size: 7px;
    color: #121212;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 8px;

  }
  .location-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    align-items: baseline!important;
  }
  .body-card-text {
    font-size: 28px;
    font-weight: 700;
    color: black;
    margin-left: 21px;
}
.image-card-section{
  display: flex;
  justify-content: space-between;
  margin-top: 5%;

}
.events-text p{
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  line-height: 24px;
  text-align: justify;
}
.button_boxes{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-box{
  width: 48%;
}
.events-text{
  width: 48%;
}
.event-img{
  width: 48%;
}
.event-img img{
  width: 100%;
}
.events-text h4{
  font-size: 28px;
    width: 75%;
    line-height: 34px;
    font-weight: 700;
    color: #131313;
}
.care-services{
    display: flex;
    justify-content: space-between;
    margin-top: 7%;
    align-items: center;
}
.care-services h6{
  color: #121212;
    font-size: 24px;
    font-weight: 700;
    width: 100%;
    height: 21px;
    width: 250px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 5px 0 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    margin: 6px 3px;
}
.tag-icon{
  display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}
.tag-icon span{
  font-size: 18px;
  color: #121212;
  font-weight: 500;
}
.tag-icon img{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
   .blog{
    /* padding-left: 100px; */
    padding-top: 35px;
    padding-right:5px;
   }
   .more{
    color:white;
    padding: 10px 20px 10px 20px;
      margin-left: 7%;
      margin-top:2%;
   }
   .blog p{
    font-size:14px;
   }
   button.btn.btn-view {
    color: orange;
    border-color: orange;
    /* margin-left: 80%; */
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
  }
  .feedback{
    background-color: #F0F0F0;
    min-height: 50vh;
    color: white;
    padding-left: 100px;
    padding-top: 20px;
  }
  .apps{
    background-color: #f5f8f8;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 32px 10rem;
    align-items: center;


  }
  .links-images{
    display: flex;
    justify-content: space-between;
  align-items: center;
  width: 80%;
  }
  .apps img{
    width: 30%;
  }
  .apps-section {
    width: 48%;
  }
  .apps-section1{
    width: 48%;
  }
  .apps-section h4{
    font-size: 26px;
    color: #292727;
    font-weight: 700;
}
.apps-section {
  border-right: 2px solid #afb2b3;
  color: #ffffff;
  padding-right: 5rem;
}
  .apps-section1 h6{
    color:black;
  }
  .footer{
    background-color: #000030    ;
    color:white;
    display: flex;
    justify-content: space-between;
    padding: 5rem 5rem;
  
  }
  .footer-wraper img {
    width: 58%;
    margin-bottom: 20px;
}
  .footer h6{
    color: #fd7e14;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .media-heading h6{
    color: #fd7e14;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bolder;
  }
  .footer_icon{
      font-weight: 400;
      margin: 20px;
      font-size: 30px;
      display: flex;
      justify-content: space-between;
      width: 60%;
  }
  
.footer_language select{
  width: 200px;
  height: 40px;
  background: transparent;
  border: 1px solid white!important;
  color: white!important;
  border-radius: 5px;
  padding: 0px 10px;
  margin-top: 5%;
}
  .apps p{
    font-size: 18px;
    color: #1f1d1d;
    text-align: justify;
    margin-top: 2%;

  }
  .apps-section h6{
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    width: 20%;
    line-height: 25px;
  }
  .feedback h4{
    font-size:35px;
    color: orange;
  }
  .feedback p{
    color: black;
    font-size: 21px;
  }
  
  button.btn.btn-outline-dark {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-bottom: 30px;
    font-size: 20px;
  }
  button.btn.btn-outline-dark:hover{
    background-color: #FD7E14!important;
    border: none!important;
  }
  .menus ul{
    display: flex;
    list-style: none;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    column-gap: 60px;
    
    margin-bottom: 0;
  }
  .customer_feedback_box{
    background: white;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    padding: 24px;
    width: 32%;
  }
  .customer-feedback-content h5{
    color: #133240;
    font-size: 22px;
    margin-top: 20px;
    font-weight: 600;

  }
  .customer-feedback-content p{
    font-size: 18px;
    color: #133240;
    font-weight: 400;
    line-height: 27px;
    text-align: justify;
    margin-top: 26px;

  }
  .rating-image-wrapper{
    display: flex;
    justify-content: space-between;
  }
  .rating-image-wrapper i{
    color: #F8A401;
    margin-right: 6px;
  }
  .customer_feedback_boxes{
    display: flex;
    justify-content: space-between;
  }
  .customer_feedback{
    background: #f5f7fa;
    padding: 58px 5rem;
    margin-top: 3%;

  }
  .related-blogs{
    padding: 20px 5rem;
  }
  .customer_feedbac_text h4{
    font-size: 32px;
    color: #F18805;
    font-weight: 700;
  
  }
  .view-more-button button{
    background: #F18805;
  font-size: 18px;
  color: white;
  border: none;
  width: 150px;
  height: 48px;
  border-radius: 10px;
  text-transform: capitalize;
  }
  .view-more-button {
    text-align: center;
    margin-top: 1%;
    margin-bottom: 2%;
  }
 
  /* .card-text p{
    color: #444444;
    font-size: 16px;
    font-weight: 400;
  } */
  .card-text h5{
    color: #888888;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .blogs-box {
    padding: 20px 5rem;
  }
  .blogs-box h2{
    font-size: 30px;
    font-weight: 700;
    line-height: 23px;
    margin-bottom: 0px;
  }
.customer_feedbac_text p{
  font-size: 22px;
    color: black;
    font-weight: 400;
}
  .App-link {
    color: #61dafb;
  }
  input#search {
    border-radius: 0px;
    height: 48px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    box-shadow: none;
    width: 350px;

  }
  select#exampleFormControlSelect1 {
    border-radius: 3px 0px 0px 3px;
    height: 48px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 150px;
    padding-left: 30px;
    background: #F3F1F2;
    font-size: 16px;
    font-weight: 600;
    color: #4D4D4D;
    border: none;
    cursor: pointer;

  }
  .search-form button {
  height: 100%;
  width: 100%;
  font-size: 1rem;
  }
  
.view_project{
  padding: 20px 5rem;
}
.numberlists{
  display: flex;
  justify-content: space-around;
  width: 36%;
  margin: 0 auto;
padding-top: 3%;
text-align: center;
}
.numberlists h3{
  color: #FD7E14;
    font-size: 40px;
    font-weight: 700;
}
.numberlists p{
  font-size: 23px;
    text-transform: capitalize;
    font-weight: 400;
    color: black;
}
.section{
  padding: 20px 5rem;
}
  .search-form button svg {
  width: 24px;
  height: 24px;
  }
  
  button.btn.btn-base {
    color: white;
    background-color: #FD7E14    ;
    border-radius: 0px 3px 3px 0px;
    height: 48px;
    padding: 10px 110px 10px 24px;
    position: relative;
  }
  .modal-title {
    padding-left: 40%;
}

button.btn.btn-base p{
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  top:8px;
  left: 50px;
  margin: 0;
}
button.btn.btn-confirm {
  color: white;
  background-color: orange;
  border-radius: 7px 7px 7px 7px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
button.btn.btn-signup {
  border-radius: 7px 7px 7px 7px;
  width: 100%;
  border-color: grey;
  padding-top: 10px;
  margin-top:1%;
  padding-bottom: 10px;
}
.form-group {
  margin-top: 3%;
}
label{
  font-weight: 500;
  font-size: 15px;
}
.account{
  padding-left:25%;
}
button.btn.btn-signup {
  font-weight: 700;
}

/* Home-page-end*/


/*privacy-policy*/
.privacy-policy{
  padding: 20px 3rem;
  margin-top: 3%;
}
.privacy-policy h1{
  font-size: 40px;
  color: #414141;
  font-weight: 600;
}
.privacy-policy p{
  font-size: 16px;
  color: #414141;
  font-weight: 400;
  line-height: 28px;
}
.privay-policy-text{
  margin-top: 3%  ;
}
.privay-policy-text h5{
  font-size: 20px;
  color: #414141;
  font-weight: 700;
}
.privay-policy-text ul{
  margin-top: 20px;
}
.privay-policy-text ul li{
   
  font-size: 16px;
  color: #414141;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 10px;
}
/* pricay-poliy-end*/


/*termconstion*/
.term-condtion-text{
  padding: 20px 3rem;
}
.term-condtion-text span{
  font-size: 16px;
    font-weight: 700;
}
.term-condtion-text p{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #414141;
}
.conditions-content{
  margin-top: 30px;
}
.conditions-content h5{
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.term-condtion-text h1{
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 3%;
  margin-top: 5%;
}
.terms-wrapper{
  margin-top: 3%;
}
.terms-wrapper h4{
  font-size: 24px;
  font-weight: 700;
  color: #414141;
}
.terms-wrapper h5{
  font-size: 22px;
  font-weight: 600;
  color: #414141;
}
.wrapper-text{
  margin: 15px 0px;
}
.wrapper-text h6{
  font-size:20px;
  color: #414141;
  font-weight: 600;
}
@media only screen and (max-width: 767px){


  .header {
    background-color: transparent;
    color: white;
    padding: 20px 1rem;
    border-bottom: 1px solid #DBDFEC;
}
.top-header{
  display: block;
  text-align: center;
}
.navbar_list{
  padding: 20px 1rem;
  display: block;
}
.menus ul {
  column-gap: 5px;
  display: flex;
  padding-left: 0px;
  margin-top: 19px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  }
  .menus li{
    font-size: 14px;
  }
  button.btn.btn-outline-light {
    padding-top: 5px;
    padding-bottom: 10px;
    margin-top: 0px;
    font-size: 13px;
}

#search-form {
  padding: 20px 1rem 5rem;
}
select#exampleFormControlSelect1{
width: 100%;
}
.location-bar .location-bar-icon-dropdown {
left: 85%;
}
input#search{
width: 100%;
}
.button{
  text-align: center;
}
.top-banner h1 {
  font-size: 30px;
  line-height: 36px;
}
.top-banner p{
  font-size: 15px;
  width: 78%;
}
  .home-icon img {
    width: 12rem;
}
.icons {
  width: 70%;
  margin: 0 auto;
  padding-top: 7%;

}

button.btn.btn-base p {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  top: 2px;
  left: 34px;
}
.search-bar {
  display: block;
}
button.btn.btn-base {
  color: white;
  background-color: #FD7E14;
  border-radius: 0px 3px 3px 0px;
  height: 48px;
  padding: 10px 110px 10px 24px;
  position: relative;
  width: 100%;
}
.top-banner {
  margin-top: 3rem;
}
.body-card-text {
  font-size: 16px;
}
.cards-items{
  display: block;
}
.view_project{
  padding: 20px 1rem;
}
.cards-items .card-image {
  width: 100%;
  margin-bottom: 30px;
}
.heading-section h6 {
font-size: 18px;
}
.heading-section button {
background: #0096CC;
padding: 2px 16px;
border: none;
border-radius: 50px;
color: white;
font-size: 14px;
}
.numberlists{
  width: 80%;
}
.numberlists{
  display: block;
}
.section {
  padding: 20px 2%;
}
.card-text h6 {
  font-size: 14px;
}
.home-icons {
  width: 100%;
  margin-top: 20px;
}
.location.location-icon p {
  font-size: 12px;
}
.card-text p {
  font-size: 13px;
}
.time i {
  width: 20px;
  height: 20px;
  font-size: 12px;
}
.apps{
  padding: 32px 1rem;
  display: block;
}
.apps-section h4 {
  font-size: 18px;
}
.apps-section{
  width: 100%;
  border-right: none;
}
.apps img {
  width: 42%;
}
.apps-section1{
  width: 100%;
}
.links-images{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
}
.links-images img{
  margin-bottom: 20px;
}
.apps p {
  font-size: 14px;
}
.view-orange-btn {
  width: 100%;
  height: 35px;
  font-size: 14px;
}
.job-text {
  font-size: 14px;
}
.care-services h6 {

  font-size: 14px;
  }
  .tag-icon span {
    font-size: 12px;

  }
  .customer_feedback_boxes {
    margin-top: 3%;
}
.customer-feedback-content h5{
  font-size: 20px;
}
.customer-feedback-content p {
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
}
.events-text h4 {
  font-size: 18px;
  width: 80%;
  line-height: 24px;
  font-weight: 700;
  color: #131313;
}
.customer_feedback_boxes{
display: block;
}
.customer_feedback_box{
width: 100%;
margin-bottom: 20px;
}
.events-text p {
font-size: 13px;
color: #000000;
font-weight: 500;
line-height: 18px;
text-align: justify;
}
.events-text {
  width: 100%;
}
.image-card-section{
display: block;
}
.event-img {
  width: 100%;
}
button.btn.btn-outline-dark {
width: 100%;
padding-top: 18px;
padding-bottom: 18px;
margin-bottom: 30px;
font-size: 12px;
}
.footer_icon {
  font-weight: 400;
  font-size: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  column-gap: 22px;
  margin-bottom: 20px;
}
.footer_language select{
margin-top: 0%;
}
.footer{
  padding: 3rem 1rem;
  display: block;
 
}
.footer-wraper img {
  width: 60%;
}
.footer-wraper p{
font-size: 14px;
  width: 100%;
  margin-top: 20px;
  text-align: justify;
}
.footer-wraper{
  margin-bottom: 20px;
  width: 100%;
}
.customer_feedback {
  padding: 58px 1rem;
}
.customer_feedbac_text h4 {
  font-size: 28px;
}
.customer_feedbac_text p {
  font-size: 18px;
  color: black;
  font-weight: 400;
  width: 100%;
}
.related-blogs {
  padding: 20px 1rem;
}
.blogs-box {
  padding: 20px 1rem;
}

 .menu-left {
  top: calc(100% + 4px);
  right: 0;
  left: 0!important;
}
/*about-us*/
.about-us-banner{
  background-image: url("images/abouttt (2).jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height:100%;
}
.home-icon{
  padding-left: 0px;
}
.button{
  padding-left: 0px;
  width: 100%;
}
.choose-theme{
  padding: 20px 1rem;
}
.main-title p {
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #414141;
  padding-top: 10px;
}
.about-us-box{
  display: block;
}
.about-us-box div{
  width: 100%;
}
.about-us-box h4{
  font-size: 32px;
}
.about-us-box p{
  font-size: 14px;
}
.content_resize{
  padding: 20px 1rem;
}
.content .mainbar h2 {
  margin-bottom: 18px;
  padding-bottom: 0;
  font-size: 28px;
  line-height: 1.2em;
  color: #FD7E14;
  font-weight: 600;
}
.main-title h3{
  font-size: 32px;
}
.address{
  padding-left: 0px;
}

}

@media only screen and (min-width: 768px) and (max-width: 1024px){
  .header {
      background-color: transparent;
      color: white;
      padding: 20px 1rem;
      border-bottom: 1px solid #DBDFEC;
  }
  .top-header{
    display: flex;
  }
  .navbar_list{
    padding: 20px 1rem;
    display: flex;
  }
  .menus ul {
    column-gap: 2px;
    display: flex;
    padding-left: 0px;
    margin-top: 19px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    line-height: 32px;
    }
    button.btn.btn-outline-light {
      padding-top: 5px;
      padding-bottom: 10px;
      margin-top: 0px;
      font-size: 13px;
      width: 115px;
  }
  #search-form {
    padding: 20px 1rem 5rem;
}
select#exampleFormControlSelect1{
  width: 100%;
}
.location-bar .location-bar-icon-dropdown {
  left: 85%;
}
input#search{
  width: 100%;
}
  .button{
    text-align: center;
  }
  .top-banner h1 {
    font-size: 35px;
  }
  .top-banner p{
    font-size: 18px;
    width: 78%;
  }
    .home-icon img {
      width: 12rem;
  }
  .icons {
    width: 30%;
   

  }
  .post-btn {
    background: #FD7E14;
    padding: 5px 25px;
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    border: none;
}
button.btn.btn-base p {
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  top: 0;
  left: 25px;
}
  .top-banner {
    margin-top: 3rem;
  }
  .body-card-text {
    font-size: 20px;
  }
  .cards-items{
    display: flex;
    flex-wrap: wrap;
  }
  .view_project{
    padding: 20px 1rem;
  }
  .cards-items .card-image {
    width: 48%;
    margin-bottom: 30px;
}
.heading-section h6 {
  font-size: 18px;
}
.heading-section button {
  background: #0096CC;
  padding: 2px 16px;
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 14px;
}
  .numberlists{
    width: 80%;
  }
  .numberlists{
    display: flex;
  }
  .section {
    padding: 20px 1rem;
  }
  .card-text h6 {
    font-size: 14px;
  }
  .home-icons {
    width: 100%;
    margin-top: 20px;
  }
  .location.location-icon p {
    font-size: 12px;
  }
  .card-text p {
    font-size: 13px;
  }
  .time i {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  .apps{
    padding: 32px 1rem;
  }
  .apps-section h4 {
    font-size: 18px;
  }
  .apps p {
    font-size: 14px;
  }
  .view-orange-btn {
    width: 100%;
    height: 35px;
    font-size: 14px;
  }
  .job-text {
    font-size: 14px;
  }
  .care-services h6 {
  
    font-size: 14px;
    }
    .tag-icon span {
      font-size: 12px;
  
    }
    .customer_feedback_boxes {
      margin-top: 3%;
  }
  .customer-feedback-content h5{
    font-size: 20px;
  }
  .customer-feedback-content p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
  }
  .events-text h4 {
    font-size: 18px;
    width: 80%;
    line-height: 24px;
    font-weight: 700;
    color: #131313;
}
.customer_feedback_boxes{
  display: flex;
}
.customer_feedback_box{
  width: 32%;
  margin-bottom: 20px;
}
.events-text p {
  font-size: 13px;
  color: #000000;
  font-weight: 500;
  line-height: 18px;
  text-align: justify;
}
.image-card-section{
  display: flex;
}
button.btn.btn-outline-dark {
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  margin-bottom: 30px;
  font-size: 13px;
}
.footer_icon {
  font-weight: 400;
  font-size: 30px;
  display: flex;
  justify-content: space-between;
  width: 90%;
  flex-wrap: wrap;
  margin-top: 25px;
}
.footer_language select{
  margin-top: 0%;
}
  .footer{
    padding: 5rem 3rem;
    display: block;
    flex-wrap: wrap;
  }
  .footer-wraper img {
    width: 28%;
}
.footer-wraper p{
  font-size: 16px;
    width: 100%;
    margin-top: 20px;
    text-align: justify;
}
  .customer_feedback {
    padding: 58px 1rem;
  }
  .customer_feedbac_text h4 {
    font-size: 28px;
  }
  .customer_feedbac_text p {
    font-size: 18px;
    color: black;
    font-weight: 400;
    width: 100%;
}
  .related-blogs {
    padding: 20px 1rem;
  }
  .blogs-box {
    padding: 20px 1rem;
  }
  /*about-us*/
.about-us-banner{
  background-image: url("images/abouttt (2).jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height:100%;
}
.home-icon{
  padding-left: 0px;
}
.button{
  padding-left: 0px;
  width: 100%;
}
.choose-theme{
  padding: 20px 1rem;
}
.main-title p {
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #414141;
  padding-top: 10px;
}
.about-us-box{
  display: block;
}
.about-us-box div{
  width: 100%;
}
.about-us-box h4{
  font-size: 32px;
}
.about-us-box p{
  font-size: 14px;
}
.content_resize{
  padding: 20px 1rem;
}
.content .mainbar h2 {
  margin-bottom: 18px;
  padding-bottom: 0;
  font-size: 28px;
  line-height: 1.2em;
  color: #FD7E14;
  font-weight: 600;
}
.main-title h3{
  font-size: 32px;
}
.address{
  padding-left: 0px;
}
  }
@media only screen and (min-width: 1025px) and (max-width: 1200px){
  .header {
      background-color: transparent;
      color: white;
      padding: 20px 3rem;
      border-bottom: 1px solid #DBDFEC;
  }
  .navbar_list{
    padding: 20px 3rem;
  }
  .menus ul {
    column-gap: 2px;
    display: flex;
    padding-left: 0px;
    margin-top: 19px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 90%;
    line-height: 38px;

    }
    .home-icon img {
      width: 12rem;
  }
  .icons {
    width: 20%;
  }
  .top-banner {
    margin-top: 6rem;
  }
  .view_project{
    padding: 20px 3rem;
  }
  .numberlists{
    width: 55%;
  }
  .section {
    padding: 20px 3rem;
  }
  .card-text h6 {
    font-size: 16px;
  }
  .home-icons {
    width: 100%;
    margin-top: 20px;
  }
  .location.location-icon p {
    font-size: 14px;
  }
  .card-text p {
    font-size: 14px;
  }
  .time i {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  
  .view-orange-btn {
    width: 100%;
    height: 35px;
    font-size: 14px;
  }
  .job-text {
    font-size: 14px;
  }
  .care-services h6 {
  
    font-size: 18px;
    }
    .tag-icon span {
      font-size: 14px;
  
    }
    .customer_feedback_boxes {
      margin-top: 5%;
  }
  .customer-feedback-content h5{
    font-size: 20px;
  }
  .customer-feedback-content p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
  }
  .footer{
    padding: 5rem 3rem;
  }
  .customer_feedback {
    padding: 58px 3rem;
  }
  .related-blogs {
    padding: 20px 3rem;
  }
  .blogs-box {
    padding: 20px 3rem;
  }
  }
  @media only screen and (min-width: 1201px) and (max-width: 1366px){
.header {
    background-color: transparent;
    color: white;
    padding: 20px 3rem;
    border-bottom: 1px solid #DBDFEC;
}
.navbar_list{
  padding: 20px 3rem;
}
.menus ul {
  column-gap: 24px;
  }
  .home-icon img {
    width: 12rem;
}
.icons {
  width: 20%;
}
.top-banner {
  margin-top: 6rem;
}
.view_project{
  padding: 20px 3rem;
}
.numberlists{
  width: 55%;
}
.section {
  padding: 20px 3rem;
}
.card-text h6 {
  font-size: 16px;
}
.home-icons {
  width: 100%;
  margin-top: 20px;
}
.location.location-icon p {
  font-size: 14px;
}
.card-text p {
  font-size: 14px;
}
.time i {
  width: 20px;
  height: 20px;
  font-size: 12px;
}

.view-orange-btn {
  width: 100%;
  height: 35px;
  font-size: 14px;
}
.job-text {
  font-size: 14px;
}
.care-services h6 {

  font-size: 18px;
  }
  .tag-icon span {
    font-size: 14px;

  }
  .customer_feedback_boxes {
    margin-top: 5%;
}
.customer-feedback-content h5{
  font-size: 20px;
}
.customer-feedback-content p {
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
}
.footer{
  padding: 5rem 3rem;
  justify-content: space-evenly;
}
.footer-wraper{
  width:22% ;
}
.customer_feedback {
  padding: 58px 3rem;
}
.related-blogs {
  padding: 20px 3rem;
}
.blogs-box {
  padding: 20px 3rem;
}
}
@media only screen and (min-width: 1367px) and (max-width: 1600px){
  .header {
      background-color: transparent;
      color: white;
      padding: 20px 3rem;
      border-bottom: 1px solid #DBDFEC;
  }
  .navbar_list{
    padding: 20px 3rem;
  }
  .menus ul {
    column-gap: 24px;
    }
    .home-icon img {
      width: 12rem;
  }
  .icons {
    width: 20%;
  }
  .top-banner {
    margin-top: 6rem;
  }
  .view_project{
    padding: 20px 3rem;
  }
  .numberlists{
    width: 55%;
  }
  .section {
    padding: 20px 3rem;
  }
  .card-text h6 {
    font-size: 16px;
  }
  .home-icons {
    width: 85%;
    margin-top: 20px;
  }
  .location.location-icon p {
    font-size: 18px;
  }
  .card-text p {
    font-size: 14px;
  }
  .time i {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  
  .view-orange-btn {
    width: 100%;
    height: 35px;
    font-size: 14px;
  }
  .job-text {
    font-size: 18px;
  }
  .care-services h6 {
  
    font-size: 18px;
    }
    .tag-icon span {
      font-size: 14px;
  
    }
    .customer_feedback_boxes {
      margin-top: 5%;
  }
  .customer-feedback-content h5{
    font-size: 24px;
  }
  .customer-feedback-content p {
    font-size: 18px;
    line-height: 28px;
    margin-top: 16px;
  }
  .footer{
    padding: 5rem 3rem;
  }
  .customer_feedback {
    padding: 58px 3rem;
  }
  .related-blogs {
    padding: 20px 3rem;
  }
  .blogs-box {
    padding: 20px 3rem;
  }
  }

@media  (max-width:1400px){
  .post-btn {
    padding: 9px 18px !important;
    height: auto !important;
    width: auto !important;
  }
}

@media (max-width:991px){
  /* #root {
    overflow-x: hidden;
  } */
}
  .rent-text p{
    font-size: 16px;
    font-weight: 400;
    color: #414141;
    width: 100%;
    height: 21px;
    width: 270px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 5px 0 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    margin: 6px 3px;
  }

  .share_icon{
    margin: 0px 10px ;
  }

  .location_style{
    font-size: 16px;
    font-weight: 400;
    color: #414141;
    width: 100%;
    height: 21px;
    width: 250px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 5px 0 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    margin: 6px 3px;
  }
  

  .need_care button{
    background: #0096CC;
    padding: 5px 7px;
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 12px;
    margin-left: 27px;
  }

  .system{
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #414141;
    width: 100%;
    height: 21px;
    width: 270px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 5px 0 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    margin: 6px 3px;
  }

  .apart_head{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  /* .ads_on_button p{
    background-color: rgb(238, 118, 6);
    color : white;
    padding: 3px 6px;
    border-radius: 3px;
    font-weight: 600;
    
  } */
  .upcoming-event {
    background-color: #E06df0;
    color: white;
    padding: 2px 2px;
    border-radius: 3px;
    font-weight: 600;
    text-align: center;
}
  .urgent-label {
    background-color: #ED3833;
    color: white;
    padding: 2px 2px;
    border-radius: 3px;
    font-weight: 600;
    text-align: center;
}



.ads_on_button p {
  font-size: 12px;
  padding: 3px;
  /* margin-top: 12px; */
}
  
  .rentAmount p {
    color: #47b86a;
    font-weight: 600;
    margin-left: 4px;
    margin-bottom: 3px;
}




.button_label{
  font-weight: 600;
  border-radius: 5px!important;
  font-size: 12px!important;
  padding: 2px 8px!important;
}

.heart_hover {
  /* background-color: white; */
  border-radius: 68%;
  position: relative;
  right: -12px;
  width: 49px;
  height: 55px;
  top: 10px;
}

.go4268192979 {
  right: 25px!important;
  position: relative !important;
  width: 65px !important;
  height: 63px !important;
  top: -21px !important;
}

/* after click heart icon */

.go2484888251 {
  width: 65px!important;
  height: 65px!important;
  position: relative!important;
  top: -21px!important;
  right: 25px!important;
}

.btn {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.rsis-container {
  width: 100% !important;
}
