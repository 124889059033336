.lister_info_section{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.switch_input_field1{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.switch_input_field{
    display: flex;
    flex-direction: column;
}


#switch_btn_wrapper{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.email_hide_message_text{
    font-size: .8rem;
    margin-left: 10px;
    color: var(--dark-gray);
}