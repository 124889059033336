.heading_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}

.heading_section>h3 {
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 28px;
    text-align: center;
    color: #101828;
}

.heading_section>p {
    font-family: var(--Inter);;
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 20px;
    text-align: center;
    color: #475467;

}