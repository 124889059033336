.Main_wrapper_section{
    padding: 30px 7rem;
 }
 .search_box {
     width: 350px;
     position: relative;
     display: flex;
       bottom: 0;
       left: 0;
       right: 0;
       margin: 0 auto;
       border-right: 1px solid #dddd;
   }
   
   .search_input {
     width: 100%;
     font-family: 'Montserrat', sans-serif;
     font-size: 16px;
     padding: 15px 45px 15px 15px;
     color: #6c6c6c;
     border-radius: 6px;
     border:none;
     transition: all .4s;
   }
   .dropdownbar{
     display: flex;
     align-items: center;
     column-gap: 14px;
     padding-left: 12px;
   }
   .search_input:focus {
     border:none;
     outline:none;
  
   }
   #parent_selector{
     border: none!important;
     outline: none;
   }
   .search_btn {
     background-color: transparent;
     font-size: 18px;
     padding: 6px 9px;
     margin-left:0px;
     border:none;
     color: #6c6c6c;
     transition: all .4s;
     z-index: 10;
   }
   
   .search_btn:hover {
     transform: scale(1.2);
     cursor: pointer;
     color: black;
   }
   
   .search_btn:focus {
     outline:none;
     color:black;
   }
   .select_dropdown {
     position: relative;
     max-width: 100%;
     width: auto;
     cursor: pointer;
     display: flex;
   }
   .select_dropdown:after {
     content: '';
     cursor: pointer;
     display: block;
     position: absolute;
     right: 34px;
     top: 3px;
     bottom: 3px;
     height: calc(100% - 6px);
     width: 2rem;
     border-radius: 2px;
     background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20width%3D%229px%22%20height%3D%226px%22%20viewBox%3D%220%200%209%206%22%20enable-background%3D%22new%200%200%209%206%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cg%3E%0A%09%3Cpolygon%20fill%3D%22%23333333%22%20points%3D%224.5%2C4.358%200.594%2C0.453%200%2C1.047%204.5%2C5.547%209%2C1.047%208.405%2C0.453%20%09%22/%3E%0A%3C/g%3E%0A%3C/svg%3E%0A');
     background-repeat: no-repeat;
     background-position: center center;
     pointer-events: none;
   }
   .select_dropdown select {
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     cursor: pointer;
     display: block;
     width: 100%;
     font-family: inherit;
     font-size: 15px;
     height: 40px;
     outline: none;
     vertical-align: middle;
     border: none;
     border-radius: 3px;
     box-shadow: none;
     padding: 0 2rem 0 12px;
     outline: none;
   }
   .select_dropdown select:focus {
     outline: none;
     background-color: #fff;
     border-color: none;
   
   }
   .select_dropdown select::-ms-expand {
     display: none;
   }
   .top_header_bar{
     display: flex;
     justify-content: space-between;
     align-items: center;
   }
   .search_bar_list{
     display: flex;
     border: 1px solid #dddd;
     padding: 0px 12px;
     height: 48px;
     border-radius: 6px;
   }
   .user_details{
     display: flex;
     align-items: center;
     column-gap: 18px;
   }
   .address_name{
     display: flex;
     align-items: center;
     column-gap: 12px;
   }
   .address_name p{
     color: #FD7E14;
     margin: 0;
     font-size: 14px;
   }
   .user_detail_lists{
     display: flex;
     align-items: center;
     column-gap: 12px;
   }
   .user_detail_lists i{
     font-size: 22px;
     color: #777777;
   }
   .search_button_list button{
     background: transparent;
     border: none;
     color: #FD7E14;
     text-transform: capitalize;
     font-weight: 400;
   }
   .post_button{
     background: #FD7E14;
     padding: 10px 32px;
     color: white;
     border: none;
     border-radius: 5px;
     font-size: 16px;
   }
   .nav_bar_menu ul{
     padding-left: 0;
     list-style: none;
     display: flex;
     justify-content: space-between;
     width: 80%;
   }
   .nav_bar_menu{
     margin-top: 30px;
     border-bottom: 1px solid #dddddd;
     padding-bottom: 16px;
   }
   .nav_bar_menu li{
     font-size: 16px;
     color: black;
     font-weight: 600;
   }
   .breadcrumb_bar{
     padding-left: 0;
     display: flex;
     list-style: none;
     column-gap: 22px;
     margin-top: 20px;
   }
   .breadcrumb_bar i{
     padding-right: 10px;
   }
   .detailsec_banner img{
     width: 100%;
   }
   .slider_image_details{
     display: flex;
     justify-content: space-between;
     margin-top: 40px;
   }
   .slider_image{
     width: 58%;
   }
   .detail_left_side{
     width: 30%;
   }
   .slider_image_det{
     width: 100%;
   }
   .address_details_text p{
     font-size: 26px;
     color: #333333;
     font-weight: 600;
   }
   .address_details_text{
     margin-top: 20px;
     display: flex;
     align-items: center;
     justify-content: space-between;
   }
   .address_details_text button{
     background: #FD1414;
     padding: 5px 36px;
     border: none;
     color: white;
     border-radius: 5px;
     font-size: 16px;
     font-weight: 500;
   }
   .location_box_wrap{
     display: flex;
     column-gap: 4px;
     align-items: center;
     margin-top: 20px;
   }
   .location_box_wrap p{
     font-size: 20px;
     margin: 0;
     font-weight: 500;
   }
   .location_box_wrap span{
     color: #949494;
     font-size: 20px;
   }
   .date_time{
     margin-top: 20px;
   }
   .date_time h4{
     font-size: 24px;
     font-weight: 600;
     border-bottom: 1px solid #e1dede;
     color: #000;
     padding-bottom: 22px;
 
   }
   .details_description_text{
     margin-top: 30px;
   }
   .details_description_text h2{
     font-size: 28px;
     font-weight: 600;
     color: black;
   }
   .details_description_text p{
     font-size: 18px;
     color: black;
     font-weight: 400;
     line-height: 32px;
     margin-top: 20px;
 
   }
   /* .slot_booking_wrap{
     display: flex;
     justify-content: space-between;
   } */
   .slot_booking_wrap p{
     color: #5c5c5c!important;
     font-size: 18px;
     font-weight: 500!important;
   }
   .slot_booking_wrap span{
     color: #000!important;
     font-size: 18px;
     font-weight: 600!important;
   }
   .client_side_details{
     border: 1px solid #dbdbdb;
     border-radius: 8px;
     padding: 18px;
 
   }
   .user_side_details{
     display: flex;
     align-items: center;
     justify-content: space-between;
   }
   .user_side_details p{
     font-size: 28px;
     font-weight: 600;
     color: black;
     margin: 0;
   }
   .user_side_details span{
    
     color: #3D3D3D;
 
     font-size: 22px;
     text-decoration: line-through;
   }
   .user_side_details{
     margin-top: 20px;
   }
   .user_side_details1 {
     display: flex;
     align-items: center;
     column-gap: 10px;
   }
   .share_icon{
    display: flex;
    align-items: center;
    column-gap: 10px;

  }
.shareWidth{
 width:150px
}
   .call_details{
     display: flex;
     align-items: center;
     column-gap: 6px;
    
   }
   .call_details i{
     color: #FD7E14;
     font-size: 22px;
   }
   .call_details p{
     font-size: 20px!important;
     font-weight: 500!important;
     margin: 0;
   }
   .headingdetails
    {
     color: #424242;
     margin: 0;
     font-size: 20px;
     font-weight: 400;
 
   }
   .userimage_details_wrap{
     display: flex;
     align-items: center;
     justify-content: space-between;
     border-top: 1px solid #ddd;
     padding-top: 15px;
     
   }
   .userimage_details_wrap1{
     display: flex;
     align-items: center;
     column-gap: 7px;
   }
   .userimage_details_wrap1 p{
     font-size: 18px;
     font-weight: 700;
     margin: 0;
   }
   .userimage_details_wrap1 span{
     color: #424242;
     font-weight: 400;
     font-size: 16px;
   }
   .website_buton button{
     background: #0096CC;
     padding: 12px 50px;
     border-radius: 10px;
     color: white;
     font-size: 18px;
     border: none;
     width: 100%;
     text-transform: capitalize;
 
   }
   .website_buton{
     margin-top: 40px;
   }
   .call_button{
     display: flex;
     justify-content: space-between;
     margin-top: 40px;
   }
   .call_button button{
     background: #FD7E14;
     padding: 12px 40px;
     border: none;
     border-radius: 10px;
     font-size: 18px;
     color: wheat;
     font-weight: 600;
     text-transform: uppercase;
   }
   .message_btn{
     background: transparent!important;
     border: 1px solid #FD7E14!important;
     color: #FD7E14!important;
   }
   .call_button button{
     width: 46%;
   }
   .map_area_wrap{
     margin-top: 40px;
     border: 1px solid #ddd;
     border-radius: 10px;
     padding: 22px;
   }
   .map_area_wrap h2{
     font-size: 30px;
     text-transform: capitalize;
     font-weight: 600;
     color: black;
   }
   .map_area_wrap img{
     width: 100%;
   }
   .location_view_btn button{
     padding: 10px 40px ;
     background: black;
     color: white;
     text-transform: uppercase;
     font-weight: 500;
     border-radius: 10px;
   }
 .location_view_btn{
   text-align: center;
   margin-top: 30px;
 }
 .report_text{
   font-size: 22px;
   font-weight: 400;
   text-align: right;
   margin-top: 20px;
 
 }
 .related_text{
   font-size: 30px;
   font-weight: 700;
   color: black;
   text-transform: capitalize;
 }
 .deatils_list_card_wrapper{
   margin-top: 50px;
 }
 .card_items{
   display: flex;
     justify-content: space-between;
     margin-top: 3%;
     column-gap: 20px;
 }
 .cards_items .card_image {
   width: 22%;
 }
 .card_wrap_box{
   background: white;
   border: none;
   box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
   border-radius: 18px;
 }
 .cardimage {
   height: 200px;
   width: 100%;
   border-radius: 0.75rem 0.75rem 0rem 0rem;
   /* background-image: url("../../src/assets/Rectangle 22926.png"); */
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
 }
 .fav_icon {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 14px;
   position: relative;
 }
.featured_ad{
  position: absolute;
  top:20px;
  left:25px
}
 .time {
   display: flex;
   align-items: center;
 }
 .heading_section {
   display: flex;
   justify-content: space-between;
   margin-top: 4%;
   align-items: center;
 }
 .heading_section h6 {
   font-size: 18px;
   font-weight: 700;
   color: #121212;
 }
 .heading_section button {
   background: #0096CC;
   padding: 2px 18px;
   font-size: 14px;
   border: none;
   border-radius: 50px;
   color: white;
 }
 .container{
   padding: 10px 20px;
 }
 .location_box {
   display: flex;
   justify-content: space-between;
   margin-bottom: 14px;
   align-items: center;
 }
 .location_icon{
   display: flex;
   align-items: center;
   column-gap: 5px;
 }

 .location_icon img{
    width: 30px;
 }
 .location_icon p {
  font-size: 16px;
    font-weight: 400;
    color: #414141;
    width: 100%;
    height: 21px;
    width: 250px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 5px 0 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    margin: 6px 3px;
 }
 .time{
   display: flex;
   justify-content: space-between;
   margin-bottom: 14px;
   align-items: center;
 }
 .time p {
   font-size: 17px;
   font-weight: 600;
   color: #414141;
   margin-left: 10px;
   margin-bottom: 0;
 }
 .detail_box {
   display: flex;
   justify-content: space-between;
   margin-top: 14px;
   margin-bottom: 20px;
   width: 100%;
   align-items: center;
 }
 .view_orange_btn{
   width: 165px;
   height: 35px;
   font-size: 14px;
   color: #FD7E14;
   border: 1px solid #FD7E14;
   background: transparent;
     font-size: 20px;
     font-weight: 500;
     border-radius: 10px;
 }
 .eye_icon {
   display: flex;
   align-items: center;
 }
 .deatils_logo_image img{
   width: 100%;
 }
 .deatils_logo_image{
   margin-top: 30px;
 }
 
 @media screen and (max-width: 767px) {
   .Main_wrapper_section {
     padding: 30px 1rem;
 }
 .top_header_bar{
   display: block;
 }
 .search_box {
   width: 32px;
   position: relative;
   display: flex;
   bottom: 0;
   left: 0;
   right: 0;
   margin: 0 auto;
   border-right: 1px solid #dddd;
 }
 .search_input{
   padding: 0px;
 }
 .user_details{
 display: block;
 }
 .address_name {
   display: flex;
   align-items: center;
   column-gap: 12px;
   margin-top: 18px;
   width: 100%;
 }
 .user_detail_lists {
   display: flex;
   align-items: center;
   column-gap: 12px;
   margin-top: 20px;
   width: 100%;
 }
 .post_button {
   background: #FD7E14;
   padding: 10px 32px;
   color: white;
   border: none;
   width: 100%;
   border-radius: 5px;
   font-size: 16px;
   margin-top: 24px;
 }
 .nav_bar_menu ul {
   padding-left: 0;
   list-style: none;
   display: flex;
   justify-content: space-between;
   width: 100%;
   flex-wrap: wrap;
   line-height: 34px;
 }
 .breadcrumb_bar {
   padding-left: 0;
   display: flex;
   list-style: none;
   column-gap: 22px;
   margin-top: 20px;
   flex-wrap: wrap;
   line-height: 32px;
 }
 .slider_image_details{
   display: block;
 }
 .slider_image{
   width: 100%;
 }
 .address_details_text h3{
   font-size: 13px;
   line-height: 18px;
 }
 .address_details_text button {
   background: #FD1414;
   padding: 5px 28px;
   border: none;
   color: white;
   border-radius: 5px;
   font-size: 13px;
   font-weight: 500;
 }
 .location_box_wrap{
   display: flex;
 }
 .location_box_wrap p {
   font-size: 13px;
   margin: 0;
   font-weight: 500;
 }
 .location_box_wrap span{
   font-size: 13px;
 }
 .date_time h4{
   font-size: 16px;
 }
 .details_description_text h2{
   font-size: 18px;
 }
 .details_description_text p {
   font-size: 13px;
   color: black;
   font-weight: 400;
   line-height: 25px;
   margin-top: 10px;
 }
 /* .slot_booking_wrap {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
 } */
 .slot_booking_wrap span {
   color: #000!important;
   font-size: 15px;
   font-weight: 600!important;
 }
 .detail_left_side{
   width: 100%;
 }
 .user_side_details span {
   color: #3D3D3D;
   font-size: 16px;
 }
 .user_side_details p {
   font-size: 20px;
 }
 .user_side_details {
   margin-top: 14px;
 }
 .share_icon img{
   width: 20px;
   height: 20px;
 }
 .call_details i {
   color: #FD7E14;
   font-size: 18px;
 }
 .call_details p {
   font-size: 15px!important;
 }
 .headingdetails h4{
   font-size: 16px;
 }
 .userimage_details_wrap1 p {
   font-size: 15px;
 }
 .userimage_details_wrap1 span {
   color: #424242;
   font-weight: 400;
   font-size: 14px;
 }
 .view_profile a{
   font-size: 13px;
 }
 .website_buton {
   margin-top: 24px;
 }
 .website_buton button{
   font-size: 15px;
 }
 .call_button button {
   background: #FD7E14;
   padding: 8px 40px;
   border: none;
   border-radius: 10px;
   font-size: 15px;
   color: white;
   font-weight: 600;
   text-transform: uppercase;
 }
 .map_area_wrap h2 {
   font-size: 24px;
 }
 .location_view_btn button {
   padding: 6px 40px;
   background: black;
   color: white;
   text-transform: uppercase;
   font-size: 13px;
   font-weight: 500;
   border: none;
   border-radius: 10px;
 }
 .report_text {
   font-size: 17px;
 }
 .related_text {
   font-size: 24px;
 }
 .card_items{
   display: block;
 }
 .card_image{
   margin-bottom: 20px;
 }
 }
 @media screen and (min-width: 768px) and (max-width: 1024px) {
   .Main_wrapper_section {
     padding: 30px 1rem;
 }
 .top_header_bar{
   display: flex;
   flex-wrap: wrap;
 }
 .search_box {
   width: 140px;
   position: relative;
   display: flex;
   bottom: 0;
   left: 0;
   right: 0;
   margin: 0 auto;
   border-right: 1px solid #dddd;
 }
 .search_input{
   padding: 0px;
 }
 .user_details{
   display: flex;
   justify-content: space-between;
   width: 100%;
 }
 .nav_bar_menu li {
   font-size: 15px;
 }
 .address_name {
   display: flex;
   align-items: center;
   column-gap: 12px;
   margin-top: 18px;
   width: 100%;
 }
 .user_detail_lists {
   display: flex;
   align-items: center;
   column-gap: 12px;
   margin-top: 20px;
   width: 100%;
 }
 .post_button {
   background: #FD7E14;
   padding: 10px 32px;
   color: white;
   border: none;
   width: 100%;
   border-radius: 5px;
   font-size: 16px;
   margin-top: 24px;
 }
 .nav_bar_menu ul {
   padding-left: 0;
   list-style: none;
   display: flex;
   justify-content: space-between;
   width: 100%;
   flex-wrap: wrap;
   line-height: 34px;
 }
 .breadcrumb_bar {
   padding-left: 0;
   display: flex;
   list-style: none;
   column-gap: 22px;
   margin-top: 20px;
   flex-wrap: wrap;
   line-height: 32px;
 }
 .slider_image_details{
   display: block;
 }
 .slider_image{
   width: 100%;
 }
 .address_details_text h3{
   font-size: 18px;
   line-height: 18px;
 }
 .address_details_text button {
   background: #FD1414;
   padding: 5px 28px;
   border: none;
   color: white;
   border-radius: 5px;
   font-size: 13px;
   font-weight: 500;
 }
 .location_box_wrap{
   display: flex;
 }
 .location_box_wrap p {
   font-size: 16px;
   margin: 0;
   font-weight: 500;
 }
 .location_box_wrap span{
   font-size: 16px;
 }
 .date_time h4{
   font-size: 18px;
 }
 .details_description_text h2{
   font-size: 24px;
 }
 .details_description_text p {
   font-size: 14px;
   color: black;
   font-weight: 400;
   line-height: 25px;
   margin-top: 10px;
 }
 /* .slot_booking_wrap {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
 } */
 .slot_booking_wrap span {
   color: #000!important;
   font-size: 15px;
   font-weight: 600!important;
 }
 .detail_left_side{
   width: 100%;
 }
 .user_side_details span {
   color: #3D3D3D;
   font-size: 16px;
 }
 .user_side_details p {
   font-size: 20px;
 }
 .user_side_details {
   margin-top: 14px;
 }
 .share_icon img{
   width: 20px;
   height: 20px;
 }
 .call_details i {
   color: #FD7E14;
   font-size: 18px;
 }
 .call_details p {
   font-size: 15px!important;
 }
 .headingdetails h4{
   font-size: 16px;
 }
 .userimage_details_wrap1 p {
   font-size: 15px;
 }
 .userimage_details_wrap1 span {
   color: #424242;
   font-weight: 400;
   font-size: 14px;
 }
 .view_profile a{
   font-size: 13px;
 }
 .website_buton {
   margin-top: 24px;
 }
 .website_buton button{
   font-size: 15px;
 }
 .call_button button {
   background: #FD7E14;
   padding: 8px 40px;
   border: none;
   border-radius: 10px;
   font-size: 15px;
   color: white;
   font-weight: 600;
   text-transform: uppercase;
 }
 .map_area_wrap h2 {
   font-size: 24px;
 }
 .location_view_btn button {
   padding: 6px 40px;
   background: black;
   color: white;
   text-transform: uppercase;
   font-size: 13px;
   font-weight: 500;
   border: none;
   border-radius: 10px;
 }
 .report_text {
   font-size: 17px;
 }
 .related_text {
   font-size: 24px;
 }
 .card_items{
   display: flex;
   flex-wrap: wrap;
 }
 .time{
   margin-bottom: 0px;
 }
 .view_orange_btn {
   width: 165px;
   height: 35px;
   font-size: 14px;
   color: #FD7E14;
   border: 1px solid #FD7E14;
   background: transparent;
   font-size: 15px;
   font-weight: 500;
   border-radius: 10px;
 }
 .card_image{
   margin-bottom: 20px;
   width: 96%;
 }
 }
 @media screen and (min-width: 1201px) and (max-width: 1366px) {
   .Main_wrapper_section {
     padding: 30px 3rem;
 }
 .top_header_bar{
   display: flex;
   flex-wrap: wrap;
 }
 .search_box {
   width: 330px;
   position: relative;
   display: flex;
   bottom: 0;
   left: 0;
   right: 0;
   margin: 0 auto;
   border-right: 1px solid #dddd;
 }
 .search_input{
   padding: 0px;
 }
 .user_details{
   display: flex;
   justify-content: space-between;
   width: 100%;
 }
 .nav_bar_menu li {
   font-size: 15px;
 }
 .address_name {
   display: flex;
   align-items: center;
   column-gap: 12px;
   margin-top: 18px;
   width: 100%;
 }
 .user_detail_lists {
   display: flex;
   align-items: center;
   column-gap: 12px;
   margin-top: 20px;
   width: 100%;
 }
 .post_button {
   background: #FD7E14;
   padding: 10px 32px;
   color: white;
   border: none;
   width: 100%;
   border-radius: 5px;
   font-size: 16px;
   margin-top: 24px;
 }
 .nav_bar_menu ul {
   padding-left: 0;
   list-style: none;
   display: flex;
   justify-content: space-between;
   width: 100%;
   flex-wrap: wrap;
   line-height: 34px;
 }
 .breadcrumb_bar {
   padding-left: 0;
   display: flex;
   list-style: none;
   column-gap: 22px;
   margin-top: 20px;
   flex-wrap: wrap;
   line-height: 32px;
 }
 .slider_image_details{
   display: flex;
   column-gap: 58px;
 }
 .slider_image{
   width: 80%;
 }
 .address_details_text h3{
   font-size: 18px;
   line-height: 18px;
 }
 .address_details_text button {
   background: #FD1414;
   padding: 5px 28px;
   border: none;
   color: white;
   border-radius: 5px;
   font-size: 13px;
   font-weight: 500;
 }
 .location_box_wrap{
   display: flex;
 }
 .location_box_wrap p {
   font-size: 16px;
   margin: 0;
   font-weight: 500;
 }
 .location_box_wrap span{
   font-size: 16px;
 }
 .date_time h4{
   font-size: 18px;
 }
 .details_description_text h2{
   font-size: 24px;
 }
 .details_description_text p {
   font-size: 14px;
   color: black;
   font-weight: 400;
   line-height: 25px;
   margin-top: 10px;
 }
 /* .slot_booking_wrap {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
 } */
 .slot_booking_wrap span {
   color: #000!important;
   font-size: 15px;
   font-weight: 600!important;
 }
 .detail_left_side{
   width: 48%;
 }
 .user_side_details span {
   color: #3D3D3D;
   font-size: 16px;
 }
 .user_side_details p {
   font-size: 20px;
 }
 .user_side_details {
   margin-top: 14px;
 }
 .share_icon img{
   width: 20px;
   height: 20px;
 }
 .call_details i {
   color: #FD7E14;
   font-size: 18px;
 }
 .call_details p {
   font-size: 15px!important;
 }
 .headingdetails h4{
   font-size: 16px;
 }
 .userimage_details_wrap1 p {
   font-size: 15px;
 }
 .userimage_details_wrap1 span {
   color: #424242;
   font-weight: 400;
   font-size: 14px;
 }
 .view_profile a{
   font-size: 13px;
 }
 .website_buton {
   margin-top: 24px;
 }
 .website_buton button{
   font-size: 15px;
 }
 .call_button button {
   background: #FD7E14;
   padding: 8px 40px;
   border: none;
   border-radius: 10px;
   font-size: 15px;
   color: white;
   font-weight: 600;
   text-transform: uppercase;
 }
 .map_area_wrap h2 {
   font-size: 24px;
 }
 .location_view_btn button {
   padding: 6px 40px;
   background: black;
   color: white;
   text-transform: uppercase;
   font-size: 13px;
   font-weight: 500;
   border: none;
   border-radius: 10px;
 }
 .report_text {
   font-size: 17px;
 }
 .related_text {
   font-size: 24px;
 }
 .card_items{
   display: flex;
   flex-wrap: wrap;
 }
 .time{
   margin-bottom: 0px;
 }
 .view_orange_btn {
   width: 165px;
   height: 35px;
   font-size: 14px;
   color: #FD7E14;
   border: 1px solid #FD7E14;
   background: transparent;
   font-size: 15px;
   font-weight: 500;
   border-radius: 10px;
 }
 .card_image{
   margin-bottom: 20px;
   width: 96%;
 }
 }
 @media screen and (min-width: 1367px) and (max-width: 1600px) {
   .Main_wrapper_section {
     padding: 30px 4rem;
 }
 .top_header_bar{
   display: flex;
   flex-wrap: wrap;
 }
 .search_box {
   width: 330px;
   position: relative;
   display: flex;
   bottom: 0;
   left: 0;
   right: 0;
   margin: 0 auto;
   border-right: 1px solid #dddd;
 }
 .search_input{
   padding: 0px;
 }
 .user_details{
   display: flex;
   justify-content: space-between;
   width: 100%;
 }
 .nav_bar_menu li {
   font-size: 15px;
 }
 .address_name {
   display: flex;
   align-items: center;
   column-gap: 12px;
   margin-top: 18px;
   width: 100%;
 }
 .user_detail_lists {
   display: flex;
   align-items: center;
   column-gap: 12px;
   margin-top: 20px;
   width: 100%;
 }
 .post_button {
   background: #FD7E14;
   padding: 10px 32px;
   color: white;
   border: none;
   width: 100%;
   border-radius: 5px;
   font-size: 16px;
   margin-top: 24px;
 }
 .nav_bar_menu ul {
   padding-left: 0;
   list-style: none;
   display: flex;
   justify-content: space-between;
   width: 100%;
   flex-wrap: wrap;
   line-height: 34px;
 }
 .breadcrumb_bar {
   padding-left: 0;
   display: flex;
   list-style: none;
   column-gap: 22px;
   margin-top: 20px;
   flex-wrap: wrap;
   line-height: 32px;
 }
 .slider_image_details{
   display: flex;
   column-gap: 58px;
 }
 .slider_image{
   width: 80%;
 }
 .address_details_text h3{
   font-size: 18px;
   line-height: 18px;
 }
 .address_details_text button {
   background: #FD1414;
   padding: 5px 28px;
   border: none;
   color: white;
   border-radius: 5px;
   font-size: 13px;
   font-weight: 500;
 }
 .location_box_wrap{
   display: flex;
 }
 .location_box_wrap p {
   font-size: 16px;
   margin: 0;
   font-weight: 500;
 }
 .location_box_wrap span{
   font-size: 16px;
 }
 .date_time h4{
   font-size: 18px;
 }
 .details_description_text h2{
   font-size: 24px;
 }
 .details_description_text p {
   font-size: 14px;
   color: black;
   font-weight: 400;
   line-height: 25px;
   margin-top: 10px;
 }
 /* .slot_booking_wrap {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
 } */
 .slot_booking_wrap span {
   color: #000!important;
   font-size: 15px;
   font-weight: 600!important;
 }
 .detail_left_side{
   width: 48%;
 }
 .user_side_details span {
   color: #3D3D3D;
   font-size: 16px;
 }
 .user_side_details p {
   font-size: 20px;
 }
 .user_side_details {
   margin-top: 14px;
 }
 .share_icon img{
   width: 20px;
   height: 20px;
 }
 .call_details i {
   color: #FD7E14;
   font-size: 18px;
 }
 .call_details p {
   font-size: 15px!important;
 }
 .headingdetails h4{
   font-size: 16px;
 }
 .userimage_details_wrap1 p {
   font-size: 15px;
 }
 .userimage_details_wrap1 span {
   color: #424242;
   font-weight: 400;
   font-size: 14px;
 }
 .view_profile a{
   font-size: 13px;
 }
 .website_buton {
   margin-top: 24px;
 }
 .website_buton button{
   font-size: 15px;
 }
 .call_button button {
   background: #FD7E14;
   padding: 8px 40px;
   border: none;
   border-radius: 10px;
   font-size: 15px;
   color: white;
   font-weight: 600;
   text-transform: uppercase;
 }
 .map_area_wrap h2 {
  font-size: 18px;
  margin-bottom: 20px;
}
 .location_view_btn button {
   padding: 6px 40px;
   background: black;
   color: white;
   text-transform: uppercase;
   font-size: 13px;
   font-weight: 500;
   border: none;
   border-radius: 10px;
 }
 .report_text {
   font-size: 17px;
 }
 .related_text {
   font-size: 24px;
 }
 .card_items{
   display: flex;

 }
 .time{
   margin-bottom: 0px;
 }
 .view_orange_btn {
   width: 165px;
   height: 35px;
   font-size: 14px;
   color: #FD7E14;
   border: 1px solid #FD7E14;
   background: transparent;
   font-size: 15px;
   font-weight: 500;
   border-radius: 10px;
 }
 .card_image{
   margin-bottom: 20px;
   width: 96%;
 }
 }
 .deatils_logo_image{
    margin: 0 auto;
    width: 70%;
 }


 .linkApart{
  display: flex;
  justify-content: space-between
 }

 .myLoader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
 }

 .contact_deto{
  margin-top: 10px;
}

 
.modal_box{
  width: 25rem;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border-radius: 10px;
 }
 .report_btn{
  background: #f17d3a;
  color: white;
  padding: 12px 40px;
  border-radius: 10px;
  border: none;
  margin-top: 22px;
 }

 .close_button{
  float: right;
    color: #f17d3a;
    border-radius: 10px;
    border: none;
    margin-top: 22px;
    font-size: 35px;
    background: transparent;
    position: relative;
    bottom: 32px;
 }

 .send_button{
float: none!important;
  color: white!important;;
  background: #f17d3a;
  border: 1px solid #f17d3a!important;
  padding: 8px 40px;
  border-radius: 10px;
  border: none;
  margin-top: 22px;
  width: 100%;
  margin: auto;
 }



 .jobs_amount span{
  color: #47b86a  !important;
 }

 .inputRadio {
  width: auto;
}
.modal_box label {
   line-height: 34px;
   display: flex;
   column-gap: 6px;
   align-items: center;
}

.custom_Msg{
margin-bottom: 30px;
}

.feature_img{
  position: absolute;
  top: 24px;
  left: 24px;
}

.detailsec_banner{
position: relative;
}


.hearty{
    position: relative;
    bottom: 182px;
    left: 259px;
}

.share_heart{
  display: flex;
  flex-direction: row-reverse;
}

.form_Date{
  font-weight: 600!important;
}
@media only screen and (max-width: 767px){
  .detailsec_banner img{
    width: 100%!important;
    height: auto!important;
   }
}