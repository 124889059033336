.main_alert ul li{
    list-style: none;
      line-height: 30px;
      box-shadow: 4px 7px 51px rgba(0, 0, 0, 0.07);
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 8px;
      background-color: white;
      width: 50%;
      margin: auto;
      margin-bottom: 20px;
      
  }

  .min_alert{
    margin: 40px 0px;
  }


  .toggle_switch_button input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.toggle_switch_button label {
	cursor: pointer;
	text-indent: -9999px;
	width: 60px;
	height: 30px;
	background: #a8a8a8;
	display: block;
	border-radius: 100px;
	position: relative;
}

.toggle_switch_button label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 1px;
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.toggle_switch_button input:checked + label {
	background: #F3B47F;
}

.toggle_switch_button input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.toggle_switch_button label:active:after {
	width: 30px;
}

.main_alert h1{
  text-align: center;
  margin-bottom: 40px;
}
.button_toggle_detail{
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767px){


.main_alert ul li {
  list-style: none;
  line-height: 30px;
  box-shadow: 4px 7px 51px rgba(0, 0, 0, 0.07);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}
.main_alert ul{
  padding-left: 0px;
  margin: 0px 30px;
}
}