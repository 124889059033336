.email_change_modal{
    min-width: 200px;
    max-width: 200px;

}

.main_wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
