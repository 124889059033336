.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content{
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 40%;
  font-size: .9rem;
  transform: translateX(-40%);
  width: 400px;
  white-space: normal;
  word-wrap: break-word;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}

.tooltip-container{
  margin-bottom: 1px;
  margin-left: 5px;
}

.tooltips_text{
  margin-bottom: 0 !important;
}