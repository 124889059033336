.JobAds{
    position: relative;
    
}
.JobAds h1{
padding-left: 60px;
padding-top: 30px;
}
.close_button {
    float: right;
    color: #f17d3a;
    border-radius: 10px;
    border: none;
    margin-top: 38px;
    font-size: 28px;
    background: transparent;
    position: relative;
    bottom: 48px;
    cursor: pointer;
}

  .ads_mod {
    width: 10rem;
  }

  .ads_mod ul li{
    list-style: none;
    margin: 10px 0px;
  }
  .fav_pop_up {
    cursor: pointer;
  }