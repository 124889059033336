.verify_modal_wrapper{
    max-width: 520px !important;
    border-radius: 10px;
    padding: 30px 40px  50px 40px !important;
    max-height: 600px !important;
}


.heading{
    font-size: 1.5rem;
    text-align: center;
}

#submit_btn{
    margin-top: 30px;
}