.banner_tag img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 100%;
  max-height: 550px;
}

/* In your CSS stylesheet */
.custom-dots {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 20px 0; /* Adjust margin as needed */
    list-style: none;
  }
  
  .custom-dots li {
    margin: 0 5px; /* Adjust the spacing between dots as needed */
  }
  
  .custom-dots li button {
    width: 12px; /* Adjust the width of the  */
    height: 12px; /* Adjust the height of the dots */
    background-color: #0074e4; /* Change the background color of the dots */
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-dots li.slick-active button {
    background-color: #ff5733; /* Change the active dot color */
  }
  