.about-us-banner{
  background-image: url("images/abouttt (2).jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height:100%;
}

/*about-us*/
.content_resize {
  background: #fbfbfb;
  padding: 20px 3rem;
  margin-top: 3%;
  margin-bottom: 3%;
}
.content .mainbar h2 {
  margin-bottom: 20px;
  padding-bottom: 0;
  font-size: 32px;
  line-height: 1.2em;
  color: #FD7E14;
  font-weight: 600;
}

.choose-theme{
  padding: 20px 3rem;
}
.main-title h3{
  font-size: 40px;
  font-weight: 700;
  color: #FD7E14;
  text-align: center;
}
.main-title p{
  font-size: 18px;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  color: #414141;
  padding-top: 10px;
}

.main-title{
  margin-top: 3%;
}
.about-us-box {
  display: flex;
  justify-content: space-between;
  margin-top: 6%;
  align-items: center;
}
.article{
  font-size: 16px;
  color: #414141;
  font-weight: 400;
  text-align: justify;
  line-height: 28px;
}
.about-us-box  div{
  width: 48%;
}
.top-banner{
  padding-bottom: 3%;
}
.about-us-box img{
  width: 100%;
}
.about-us-box  h4{
  font-size: 40px;
  font-weight: 600;
  color: #FD7E14;
  text-transform: capitalize;
}
.about-us-box p{
  font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: #414141;
    
}
.about-us-box button{
  padding: 10px 50px;
  background:  #FD7E14; ;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-top: 5%;
}
/*about-us-end*/