#room_rents_form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.working_hours_heading {
    margin-bottom: 10px !important;

}

.form_button_section {
    margin-top: -15px !important;
}


.toggle_switch_btn input[type=checkbox] {
    height: 0 !important;
    width: 0 !important;
    visibility: hidden !important;
}

.toggle_switch_btn label {
    cursor: pointer !important;
    text-indent: -9999px !important;
    width: 35px !important;
    height: 18px !important;
    background: grey !important;
    display: block !important;
    border-radius: 100px !important;
    position: relative !important;
}

.toggle_switch_btn label:after {
    content: '' !important;
    position: absolute !important;
    top: 3px !important;
    left: 5px !important;
    width: 12px !important;
    height: 12px !important;
    background: #fff !important;
    border-radius: 90px !important;
    transition: 0.3s !important;
}

.toggle_switch_btn input:checked+label {
    background: var(--orange) !important;
}

.toggle_switch_btn input:checked+label:after {
    left: calc(100% - 5px) !important;
    transform: translateX(-100%) !important;
}




/* working hours */
.working_hours_wrapper {
    background-color: rgb(247, 237, 237);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 7px;
}

.working_hours_close_text_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.working_hours_wrapper {
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    text-align: center;
    color: #475467;
}

.checkbox_for_24_hours {
    display: flex;
}


.close_text {
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 400;
    font-size: .8rem;
    line-height: 20px;
    text-align: center;
    color: var(--black1) !important
}

.working_hours_section {
    display: flex;
    align-items: center;
    gap: 10px;
}

.start_end_time_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    text-align: left;
    margin-top: 7px;
}

#time_input_label {
    margin-left: 5px !important;
    font-size: .9rem !important;
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    text-align: center;
    color: #475467;
}


/* accreditation section */
.heading_add_more_bt_section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 10px;
    gap: 20px;
}

.heading_add_more_bt_section>p {
    margin-bottom: 0px !important;

}

.add_more_button {
    background-color: var(--orange);
    width: fit-content;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
}

.upload_accreditation_section_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    cursor: pointer;
}

.upload_accreditation_section {
    position: relative;
}

.image_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    background: #f5e9e9;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    flex-direction: column;
}

.file_type_text {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 18px 0;
    color: var(--dark-blue) !important;

}

#file_name_text{
    margin-top: -10px !important;
    padding: 0 20px;
}

.click_to_upload_btn_text {
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 500;
    font-size: .9rem !important;
    line-height: 20px;
    color: var(--dark-blue) !important;
    margin-top: 5px;
    padding: 0 30px;
}

.closs_icon {
    position: absolute;
    top: 9px;
    right: 10px;
    background: white;
    border-radius: 3px;
    cursor: pointer;
}

#name_input {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    height: 45px !important;
}

.demo {
    color: red !important;
}

#asjgdfhasdfhasgdfha{
    visibility: 0 !important;
    opacity: 0 !important;
}