#room_rents_form{
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 20px !important;
}

.form_button_section{
    margin-top: -15px !important;
}

.expected_salary_input_wrapper {
    display: grid;
    grid-template-columns: 18% 54% 28%;
}

#currency_input_field{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-right: none !important;
}

#expected_salary_input{
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

#expected_salary_input_rate {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-left: none !important;
}


