.Eventing-banner{
    background-image: url("images/Group 1686552432.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height:100%;
  }
   .time.timepicker i{
    color: #313131;
    border: none;
    font-size: 16px;
  }
  .time.timepicker p{
    color: #313131;
    font-weight: 400;

  }