
.checkboxLabel{
    font-size: 0.95rem;
    display: inline-block;
    color: var(--light-blue2);
    font-weight: 500;
    margin: 0 0 0 10px;
}

.textfieldWrapper input,
.textfieldWrapper textarea,
.dropDownContainer{
    font-weight: 500;
    font-size: 1.05rem;
    border-radius: 5px;
    width: 100%;
    display: block;
    border: 2px solid var(--light-blue2);
}
.textfieldWrapper input,
.textfieldWrapper textarea{
    padding: 10px 20px;
}
.dropDownContainer{
    padding: 10px;
}
.textfieldWrapper input:focus,
.textfieldWrapper textarea:focus{
    border-color: var(--blue2);
}

.textfieldWrapper input::placeholder,
.textfieldWrapper textarea::placeholder{
    font-weight: 500;
    opacity: 0.8;
    color: var(--dark-blue1);
}

.inputErrorMsg{
    color: var(--error-red-dark);
    font-weight: 400;
    font-size: 0.8rem;
    margin: 2px 0 0;
}


.subSection{
    border-radius: 5px;
    background: var(--light-grey3);
    padding: 20px 20px 40px;
}


.subSectionHeading{
    color: var(--black1);
    margin-bottom: 10px;
    font-size: 0.85rem;
    font-weight: 500;
}


.innerSection{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

/* .comboboxInput */
.comboBoxContainer,
.multiSelectContainer{
    font-weight: 500;
    font-size: 1.05rem;
    border-radius: 5px;
    width: 100%;
}
.multiSelectWrapper {
    margin-bottom: 20px;
}

.multiSelectInputWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
}

.textInput {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 5px;
    font-size: 14px;
}

.tagsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
}

.tagBadge {
    background-color: #e0e0e0;
    color: #333;
    padding: 5px 10px;
    margin: 3px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    position: relative;
    margin-right: 10px;
}

.tagRemove {
    margin-left: 10px;
    cursor: pointer;
    font-size: 13px;
    position: absolute;
    top: -5px;
    right: -5px;
    text-align: center;
    background-color: orangered;
    color: white;
    border-radius: 50%;
    height: 17px;
    width: 17px;
}


/* Dropdown */
.dropdownListItem{
    font-weight: 500 !important;
    font-size: 0.95rem !important;
}

/* Timepickr */
#time_input_label {
    margin-left: 5px !important;
    font-size: .9rem !important;
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    text-align: center;
    color: #475467;
}


.required_field{
    color: var(--red) !important;
}




@media (max-width: 700px){
    .innerSection{
        grid-template-columns: none;
        grid-auto-flow: row;
        grid-auto-rows: auto;
        gap: 30px;
    }

}

/* MEDIA QUERIES */
/* @media (max-width: 900px){
    .formElementLabel{
        font-size: 0.9rem;
        margin: 0 15px;
    }

    .textfieldWrapper input,
    .textfieldWrapper textarea,
    .comboBoxContainer{
        font-size: 0.95rem;
    }
} */

.word_count_text{
    font-size: .8rem;
    color: var(--red);
    margin-top: 5px;
}