.input_Wrapper>input[type="text"],
.input_Wrapper>input[type="number"],
.input_Wrapper>select,
.input_Wrapper>textarea {
    height: 44px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 200 !important;
    font-size: 1rem !important;
    line-height: 24px !important;
    color: var(--black1);
    padding-left: 15px;
    padding-right: 15px;
    touch-action: none !important;
    /* pointer-events: none !important; */
}


.radio_btn {
    width: fit-content !important;
    width: 100px !important;
}

select option[disabled] {
    color: var(--red) !important;
    font-weight: bold !important
}

.required_field {
    color: var(--red);
}


.option_text{
    font-size: .9rem;
}

.select_input_wrapper{
    display: flex;
    align-items: center;
}

.cross_btn{
    margin-left: -35px;
    cursor: pointer;
}

#select_input{
    appearance: none !important;
    -webkit-appearance: none  !important;
    -moz-appearance: none  !important;
    background-image: none  !important;
    padding-right: 10px  !important; 
} 

/* 
.input_Wrapper > input:invalid:not(:placeholder-shown){
    border: 2px solid red !important;
  }

  .input_Wrapper>textarea:invalid:not(:placeholder-shown) {
      border: 2px solid red !important;
  }
  
.input_Wrapper > input:valid:not(:placeholder-shown){
    border: 2px solid green !important;
  }

  .input_Wrapper>textarea:valid:not(:placeholder-shown) {
      border: 2px solid green !important;
  }
    */

.input_Wrapper>textarea {
    height: auto !important;
    border: 1px solid var(--light-gray) !important;
}

.input_Wrapper>select {
    margin-top: 0 !important;
}

.input_Wrapper>label {
    font-family: var(--Inter);
    ;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem !important;
    line-height: 20px;
    color: var(--dark-blue) !important;
}

.checkbox_input_wrapper {
    display: flex !important;
    align-items: center;
    gap: 20px;
    height: 35px !important;
}

.checkbox_input_wrapper>label {
    margin-bottom: 0 !important;
}

.checkbox_input {
    width: 15px !important;
}

input[type=checkbox]+label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.5em;
    font-family: 'Arial';
    margin-left: 5px;
}

input[type=checkbox] {
    display: none;
}

/* #wdhgqqwgfdhqwf input[type=checkbox]+label:before {
    margin-right: 10px !important;
} */

input[type=checkbox]+label:before {
    content: "\2714";
    border: 0.1em solid #ED820A;
    border-radius: 0.2em;
    display: inline-block;
    
    width: 1.4em;
    height: 1.4em;
    padding-left: 0.2em;
    padding-bottom: 0.5em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
    margin-right: 10px !important;
}

input[type=checkbox]:checked+label:before {
    background-color: #ED820A;
    border-color: #ED820A;
    color: #fff;
    margin-right: 10px;
}



/* input[type=checkbox]+label:active:before {
    transform: scale(0);
}


input[type=checkbox]:disabled+label:before {
    transform: scale(1);
    border-color: #aaa;
}

input[type=checkbox]:checked:disabled+label:before {
    transform: scale(1);
    background-color: #F7C28F;
    border-color: #F7C28F; */
/* } */

.accordion_change{
    padding: 20px;
    background-color: #ED820A;
}