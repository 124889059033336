.Jobs_wraper_palces{
    text-align: center;
}
.Jobs_wraper_palces h2{
    font-size: 26px;
    font-weight: 500;
    color: #313413;
}
.Jobs_wraper_palces p{
    font-size: 16px;
    color: #313413;
    line-height: 29px;
}
.Jobs_wraper_images{
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
    padding: 20px 2rem;
}
.place_text{
    position: absolute;
    top: 70%;
    display: flex;
    padding: 18px 20px 0px;
}
.place_text h3{
    font-size: 20px;
    margin-bottom: 0px;
    color: white;
    font-weight: 600;
    margin-left: 14px;
}
.place_text p{
    padding-left: 13rem;
    color: white;
    font-size: 16px;
    margin-bottom: 0px;
}
.Jobs_wraper_images{
    position: relative;
  }
  .virtual_job_box{
    display: flex;
    justify-content: space-between;
    padding: 10px 2rem;
    position: relative;
  }
  .place_text_virtual{
    position: absolute;
    top: 70%;
    display: flex;
    padding: 18px 20px 0px;
  }
  .place_text_virtual h3{
    font-size: 20px;
    margin-bottom: 0px;
    color: white;
    font-weight: 600;
    margin-left: 14px;
}
.place_text_virtual p{
    padding-left: 13rem;
    color: white;
    font-size: 16px;
    margin-bottom: 0px;
}