.location_input_section{
    margin-top: 5px;
}

.location_ul_lists{
    list-style: none;
    margin-left: -30px !important;
    margin-top: 10px;
    
}

.location_ul_lists > li{
    padding: 7px 15px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 2px;
    background: var(--light-gray);
}

.required_field{
    color: var(--red);
}

#tooltip_text_wrapper{
    left: 30% !important;
    transform: translateX(-30%) !important;
}

.location_ul_lists {
    display: contents;
    list-style: none;
    margin-left: -30px !important;
    margin-top: 10px;
    /* position: fixed; */
    z-index: 300;
    width: 350px;
    position: relative;
}

input{
    width: 100%;
}
