.modal_wrapper{
    max-width: 520px !important;
    border-radius: 10px;
    padding: 30px 40px  50px 40px !important;
    max-height: 600px !important;
}

.ads_categories_modal_wrapper{
    max-width: 580px !important;
    border-radius: 10px;
    padding: 30px 40px 40px 40px !important;
    max-height: 600px !important;
}

.dete_account_modal_wrapper{
    padding-bottom: 25px !important;
}
.profile_user_detail_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.profile_user_detail_wrapper > svg{
    cursor: pointer;
    filter: brightness(0.5);
}

.username_text{
    font-size: 18px;
    color: white;
    font-weight: 400;
    margin-bottom: 0 !important;
}

.loginAndSignupButton{
    display: flex;
    gap: 10px;
}

.profile_logout_btn{
    display: flex;
    gap: 15px;
    position: relative;
    border: 1px solid;
    color: #d8d8d8;
    border-radius: 4px;
    padding: 5px 0px 5px 0px;
    cursor: pointer;
    background-color: rgb(240, 238, 238);
}

.username_text{
  color: #414141;
}
.profile_user_detail_wrapper{
  padding: 0px 10px;
}
.profile_dropdown{
  padding: 20px 20px 10px 20px;
  background-color: var(--white);
  position: absolute;
  top: 50px;
  right: -1%;
  transform: translate(0%, 0%);
  border-radius: 5px;
  display: none;
  width: max-content !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.profile_dropdown >figure {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.profile_dropdown > figure> p{
    margin-bottom: 0 !important;
    color: #fd7e14 ;
    cursor: pointer;
}

.profile_dropdown > figure> p >a{
  color: #fd7e14 !important;
  text-decoration: none;
}

.active{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    z-index: 600;
    background: white;
}

.search_query{

width: 23%;
display: flex;
align-items: center;
}

.search_query input{
    padding: 20px 30px;
}
.search_query i{
    color: black;
    background-color: white;
    padding: 16px 20px;
    border: none;
}

.search_box {
    width: auto;
    position: relative;
    display: flex;
      bottom: 0;
      left: 0;
      right: 0;
      border-right:none;
      display: flex;
      align-items: center;
  }
  .search_box i{
    color: #6c6c6c;
  }
  
  .search_input {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    padding: 15px 45px 15px 15px;
    color: #6c6c6c;
    border-radius:4px;
    border:none;
    transition: all .4s;
  }
  .dropdownbar{
    display: flex;
    align-items: center;
    border: none;
    padding: 0 5px;
    border-radius: 0;
    height: 50px;
    background: transparent;
    color: #fd7e14;
  }
  .search_input:focus {
    border:none;
    outline:none;
 
  }
  #parent_selector{
    border: none!important;
    outline: none;
  }
  .search_btn {
    background-color: transparent;
    font-size: 18px;
    padding: 6px 9px;
    margin-left:0px;
    border:none;
    color: #6c6c6c;
    transition: all .4s;
    z-index: 10;
  }
  
  .search_btn:hover {
    transform: scale(1.2);
    cursor: pointer;
    color: black;
  }
  
  .search_btn:focus {
    outline:none;
    color:black;
  }
  .select_dropdown {
    position: relative;
    max-width: 100%;
    width: auto;
    cursor: pointer;
    display: flex;
  }
  .select_dropdown:after {
    content: '';
    cursor: pointer;
    display: block;
    position: absolute;
    right: 34px;
    top: 3px;
    bottom: 3px;
    height: calc(100% - 6px);
    width: 2rem;
    border-radius: 2px;
    background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20width%3D%229px%22%20height%3D%226px%22%20viewBox%3D%220%200%209%206%22%20enable-background%3D%22new%200%200%209%206%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cg%3E%0A%09%3Cpolygon%20fill%3D%22%23333333%22%20points%3D%224.5%2C4.358%200.594%2C0.453%200%2C1.047%204.5%2C5.547%209%2C1.047%208.405%2C0.453%20%09%22/%3E%0A%3C/g%3E%0A%3C/svg%3E%0A');
    background-repeat: no-repeat;
    background-position: center center;
    pointer-events: none;
  }
  .select_dropdown select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    display: block;
    width: 100%;
    font-family: inherit;
    font-size: 15px;
    height: 40px;
    outline: none;
    vertical-align: middle;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    padding: 0 2rem 0 12px;
    outline: none;
  }
  .select_dropdown select:focus {
    outline: none;
    background-color: #fff;
    border-color: none;
  
  }
  .select_dropdown select::-ms-expand {
    display: none;
  }
  .top_header_bar{
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    align-items: center;
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px -3px 20px 0px;
  }
  .search_bar_list{
    display: flex;
    border:none;
    padding: 0 5px;
    border-radius: 6px;
    border: 1px solid #d5d5d5;
    align-items: center;
    column-gap: 20px;
  }
  .user_details{
    display: flex;
    align-items: center;
    column-gap: 6px;
  }
  .address_name{
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
  .address_name p{
    color: #FD7E14;
    margin: 0;
    font-size: 14px;
  }
  .user_detail_lists{
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
  .user_detail_lists i{
    font-size: 22px;
    color: #777777;
  }
  .search_button_list button{
    background: transparent;
    border: none;
    color: #FD7E14;
    text-transform: capitalize;
    font-weight: 400;
  }
  .post_button{
    background: #FD7E14;
    padding: 10px 32px;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
  }
  .nav_bar_menu ul{
    list-style: none;
    display: flex;
    width: 100%;
    padding: 20px 15px;
    margin-bottom: 0px;
    column-gap: 100px;
    padding-left: 80px;
  }
  .nav_bar_menu{
    margin-top: 5px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 16px;
    margin-left: 0;
  }
  .Dropdown_dropdown__ZbOaq .Dropdown_menu-left__-aYko{
    height: 200px;
    overflow-y: scroll;
}
  .nav_bar_menu li{
    font-size: 16px;
    color: black;
    font-weight: 600;
    cursor : pointer
  }
  .breadcrumb_bar{
    padding-left: 0;
    display: flex;
    list-style: none;
    column-gap: 22px;
    margin-top: 20px;
  }
  .breadcrumb_bar i{
    padding-right: 10px;
  }


  .custom_menu{
    background-color: #ffffff;
    color: black;
    border: none;
    font-weight: 600;
  }

  .nav_bar_menu button {
    background-color: #ffffff!important;
    color: #172B4D!important;
    font-size: 15px!important;
}

/* 
  .dropdown_container {
    position: relative;
    display: flex;
  }
  
  .subcategory_container {
    margin-left: 276%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: block;
    width: 200px;
  }
  
  .subcategory_item {
    padding: 5px;
    cursor: pointer;
  }
  
  .subcategory_item:hover {
    background-color: #f5f5f5;
  } */

  .submenu_vertical {
    display: flex;
    flex-direction: column;
  }
  .menu_vertical{
    background-color: #FD7E14;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 40%;
  }
  

  .submenu_container {
    display: flex;
    flex-direction: column;
  }
  

  
  .submenu {
    left: 100%;
    background-color: transparent!important;
    box-shadow: none!important;
    margin-left: 20px;
    position: absolute;
    z-index: 1000;
  }
  
  .submenu_content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    /* border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    z-index: 1;
    padding: 10px;
  }
  .submenu_container .item{
    display: flex!important;
    justify-content: space-between!important;
  }
  .category_right{
    display: flex;
    justify-content: space-between;
  }
  
  .linkView{
    color: #FD7E14;
    text-decoration: underline;
  }

  .menuView{
    margin-left: 10px;
  }
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light border */
    border-top: 4px solid #36D7B7; /* Blue border on top */
    border-radius: 50%; /* Make it circular */
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* Animation effect */
    margin: 20px auto; /* Center the loader */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg); /* Starting position */
    }
    100% {
      transform: rotate(360deg); /* Ending position */
    }
  }
  



  .category_menu{
    display:flex;
    align-items:center ;
    justify-content:space-between;
     width:300px ;
     width:300px ;
     }
  
     .banner_img{
      text-align: center;
      margin: 50px auto 0px;
      
     }
    
.banner_img img{
  width: 92%;
}

.my_banner{
  width: 100%;
  position: relative;
bottom: 10%;
}
.fav_pop_up ul li{
  list-style: none;
    line-height: 30px;
    box-shadow: 4px 7px 51px rgba(0, 0, 0, 0.07);
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    background-color: white;
    
}

.header_mod {
  width: 22rem;

}


.fav_pop_up{
  margin-top: 28px;
}
.close_button{
  float: right;
    color: #f17d3a;
    border-radius: 10px;
    border: none;
    margin-top: 22px;
    font-size: 28px;
    background: transparent;
    position: relative;
    bottom: 68px;
    cursor: pointer;
}

.fav_pop_up ul{
  padding: 0px;
  display: contents;
}



.notification_link {
  position: relative;
}

.notification_count {
  position: absolute;
  top: -8px; 
  right: -6px; 
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 1px 3px;
  font-size: 10px;
}

.fav_tab{
  cursor: pointer;
}
.ads_styles{
  list-style: none;
  display: flex !important;
  align-items: flex-start !important;
  flex-direction: column !important;
}
.location_feature{
  display: flex;
  align-items: center;
}
.location_feature span {
  color: rgb(78, 73, 73);
}

.location_feature h6{
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    height: 21px;
    width: 120px;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 0 5px 0 5px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fd7e14;
    margin: 6px 3px;
}

.location_feature  img{
  width: 20px;
}

/* .filter_loc_header {
  position: relative;
  bottom: 50px;
} */

.header_mod_loc {
  width: 32rem;
  height: 18rem;
}


.setItem{
  color: white;
  float: right;
  background: #F17D3A;
  padding: 5px 13px;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 50px;
}

/* .header_style {
     width: 45%;
    display: flex;
    align-items: center;
    border-left: 1px solid #c9c7c7;
    margin: 6px 0px;
    padding-left: 10px;
    border-right: 1px solid #c9c7c7;
    padding-right: 10px;

} */

.header_content{
  margin-top: 12px;
  font-size: 14px;
}
  
.search_input {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  padding: 15px 5px 15px 15px;
}
.top_header_bar_logo{
  width: 14rem;
}
.header_style select{
  border: none!important;
}
.search_input{
  border: none!important;
  width: 212px;
}

@media (max-width:1400px){


.header_style select{
  min-width: 195px !important;
  width: 195px !important;
}

/* .search_bar_list{
    flex:  0 0 650px;
} */

  .top_header_bar , .user_details{
    flex-wrap: wrap;
    row-gap: 20px;
}

.nav_bar_menu ul{
  flex-wrap: wrap;
  column-gap: 26px !important;
  justify-content: start;
  padding-left: 50px;
}

.user_details{
   flex: 1;
   row-gap: 10px;
   justify-content: flex-end;
   align-items: center;
}
}

@media (max-width:991px){
  .search_bar_list{
    order: 3;
    margin-left: auto;
}

.top_header_bar_logo {
  width: 190px;
}
}

@media only screen and (max-width: 767px){
  /* .top_header_bar{
    display: block;
    padding: 10px 2%;
    position: relative;
  } */
  /* .user_details{
    display: block;
  } */
  /* .search_bar_list {
    display: flex;
      border: 1px solid #BBB9B9;
      padding: 0px 10px;
      height: 48px;
      border-radius: 6px;
      flex-wrap: wrap;
  } */
  /* .dropdownbar{
    display: flex;
    align-items: center;
    border: none;
    padding: 0 0px;
    border-radius: 0;
    height: 50px;
    background: transparent;
    color: #F17D3A;
    position: absolute;
    right: 24px;
  } */
  /* .dropdownbar {
    display: flex;
    align-items: center;
    border: 1px solid #f17d3a;
    padding: 0 28px;
    border-radius: 0;
    height: 50px;
    background: #f17d3a;
    color: white;
  } */

  /* .address_name{
    display: block;
    margin-top: 60px;
  } */

  .loginAndSignupButton button{
    display: flex;
}

.location_feature h6{
  height:auto;
  width: auto;
}


/* .search_button_list{
  border-left: 1px solid #B5B2B2;
    padding-left: 15px;
} */

/* .header_style{
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #C9C7C7;
  margin: 6px 0px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  margin-top: 15px;
} */

/* .search_box{
  width: auto;
  position: relative;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  border-right: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100%;
} */

  /* .user_detail_lists{
    display: flex;
    margin-top: 15px;
  } */
  .notification_link{
    position: relative;
    top: 8px;
}
.user_detail_lists span{
  margin-top: 12px;
}
.cat_name{
  padding:8px 18px !important;
  color: pink !important;
}
.cat_name:hover{
  color: orange !important;
}
.sub_cat_name{
  color: pink !important;
}
.nav_bar_menu {
  margin-top: 14px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 0px;
  margin-left: 0;

}


.dropdown .Dropdown_button.Dropdown_button-secondary {
  background-color: transparent!important;
  color: #172B4D;
}
.header_content {
  margin-top: 14px;
  font-size: 13px;
  color: #414141;
  margin-bottom: 0px;
}
.location_feature h6 {
  cursor: pointer;
  font-size: 14px;
}
.search_button_list button {
  background: transparent;
  border: none;
  color: #FD7E14;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
}
.username_text {
  font-size: 14px;
}
.nav_bar_menu ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px;
  flex-wrap: wrap;
}
.profile_user_detail_wrapper > svg {
  cursor: pointer;
  filter: brightness(0.5);
  width: 100%;
}
.footer h6 {
  color: #fd7e14;
  font-size: 20px;
  margin-bottom: 20px;
}
.nav_bar_menu ul {
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 0px;
  column-gap: 0;
  flex-wrap: wrap;
}


.search_input{
  width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    padding: 0;
    background: transparent;
}



.notification_count {
  position: absolute;
  top: -20px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 69%;
  padding: 2px 5px;
  font-size: 9px;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
}

@media (max-width:575px){
  .top_header_bar {
     flex-direction: column;
    }

    .user_details{
      justify-content:center;
   }

   .search_bar_list{
     flex-wrap: wrap;
   }

   .header_style {
    border: 1px solid #d5d5d5 ;
    order: 2;
   }

   .search_box {
    border: 1px solid #d5d5d5 ;
    height: 50px;
    padding: 0 10px;
    flex: 1;
   }
}

@media only screen and (min-width: 1367px) and (max-width: 1600px){
  .top_header_bar{
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
  }
  
.header_style {
  border-left: 1px solid #c9c7c7;
  margin: 6px 0px;
  padding-left: 10px;
  border-right: 1px solid #c9c7c7;
  padding-right: 10px;
  padding: 1px 6px;
  height: 50px;
  display: flex;
    align-items: center;
  /* width: 100%; */
}
.username_text{
  font-size: 14px;
  color: #414141;
  font-weight: 400;
  margin-bottom: 0 !important;
}
.nav_bar_menu ul {
  list-style: none;
  display: flex;
  width: 100%;
  padding: 20px 15px;
  margin-bottom: 0px;
  column-gap: 50px;
  padding-left: 80px;
}
}



/* Adjust the width based on the length of the longest category text */
.customSelectLong {
  width: 150px; /* Adjust as needed for longer text */
}

/* Set a default minimum width */
.customSelect {
  width: 20px;
}

#mySelect {
	width: 170px;
}


.header_style {
  border-left: 1px solid #c9c7c7;
  margin: 6px 0px;
  padding-left: 10px;
  border-right: 1px solid #c9c7c7;
  padding-right: 10px;
  padding: 1px 6px;
  height: 50px;
  display: flex;
    align-items: center;
  /* width: 100%; */
}

.chat_icon_header{
  font-size: 22px;
}

.chat_icon_header i{
  color: rgb(0, 0, 0);
}


@media(max-width:1400px){
  .top_header_bar_logo {
      width: 10rem;
  }

  .search_box {
    max-width: 170px;
  }
}