.close_icon_wrapper,
.cancel_icon_wrapper {
    position: absolute;
    top: 5px;
    right: 6px;
    padding: 9px 3px;
    display: inline-grid;
    place-items: center;
    place-content: center;
    cursor: pointer;
    z-index: 2;
}

.close_icon_wrapper {
    background: var(--white);
}

.cancel_icon_wrapper {
    background: var(--error-red-dark);
}