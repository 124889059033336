.profile_section_wrapper {
    padding: var(--whitespace);
}

.verify_modal_wrapper{
    max-width: 520px !important;
    border-radius: 10px;
    padding: 30px 40px  10px 40px !important;
    max-height: 600px !important;
}


.input_Wrapper {
    margin-bottom: 20px;
}

.loader_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }

#pet-select {
    display: none !important;
}

.input_Wrapper>label {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
}


select {
    height: 48px;
    padding: 5px;
}

.input_Wrapper>input {
    background: var(--white);
    border: 0.5px solid #E1E1E1;
    padding: 5px;
    width: 100%;
    margin-top: 6px;
    height: 48px;
    outline: none;
}

.input_Wrapper>input::placeholder {
    font-family: var(--NeueHaasGrotesk);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #6F758B;
    padding-left: 6px;
}

textarea::placeholder {
    border: none !important;
    color: #6F758B;
    font-size: 15px;

}

textarea {
    overflow: hidden;
    padding: 5px 5px !important;
}


textarea,
input {
    padding-left: 15px !important;

}



/* profile */

.public_info_section_wrapper,
.private_info_section_wrapper{
    position: relative;
}

.private_info_section,
.public_info_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    border: 2px solid var(--orange);
    border-radius: 4px;
    padding: 30px 40px;
    margin-bottom: 40px;
}

.public_info_heading,
.private_info_heading{
    position: absolute;
    top: -3%;
    background: var(--white);
    left: 3%;
    padding: 0 20px;
    color: var(--orange);
    font-size: 1rem;
    font-weight: 400;
    border-radius: 10px;
}

.profile_image_section,
.submit_btn {
    grid-column: 1 / span 2;
}

.submit_btn>button{
    font-size: 1.1rem;
    border-radius: 5px;;
}

.profile_image_section {
    margin: 0 auto;
    min-height: 100px;
    height: 140px;
    max-width: 140px;
    background-color: var(--orange);
    border-radius: 50%;
    position: relative;
    margin-top: 10px;
}

.change_phone_number_text,
.delete_mobile_number_text,
.change_email_address_text {
    text-align: right;
    color: var(--orange);
    margin-top: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
}

#update_email_form{
    margin-top: 30px;
}

.email_change_info_section{
    padding: 20px;
    text-align: center;
}

.email_change_info_section > span{
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.email_change_info_section > p{
    font-size: 1rem;
    color: var(--dark-gray);
}


.change_number_section_wrapper {
    display: grid;
    grid-template-columns: 85px 81%;
}

#currency_input_field {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-right: none !important;
}

#phone_number_input_field {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.modal_wrapper {
    max-width: 500px !important;
    border-radius: 5px;
}

.change_phone_number_modal{
    max-width: 550px !important;
    border-radius: 5px;
}



.name_initial {
    font-size: 4rem;
    color: var(--white);
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.camera_icon {
    position: absolute;
    top: 85%;
    right: 2%;
    cursor: pointer;
}

.cross_icon{
    position: absolute;
    top: 104%;
    right: -2%;
    cursor: pointer;
    color: red;
    width: max-content;
    border: 1px solid var(--dark-gray);
    padding: 1px 10px;
    font-size: 0.8rem;
    border-radius: 2px;
}


#location_input_wrapper {
    margin-top: -20px !important;
}

#dob_input_wrapper {
    margin-top: -35px !important;
}

#dob_input_wrapper1{
    margin-top: -50px;
}

.joined_text{
    text-align: center;
    margin-bottom: 0px !important;
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem !important;
    line-height: 20px;
    color: var(--dark-blue) !important;
    margin-top: 38px !important;
}

#join_since_text{
    margin-top: 10px !important; 
}
.joined_text >span{
    font-weight: 700;
}



.radio_button_list {
    display: inline-flex;
    gap: 10px;
    align-items: center;

}

.radio_button >p{
    margin-bottom: 0 !important;
}

#gender_input_wrapper1{
    margin-top: -15px;
}

#gender_input_wrapper{
    margin-top: -30px;
}

#gray_background{
    background-color: var(--light-gray);
}

.profile_update_btn{
    top: -11% !important;
    right: -3% !important;
}

.change_delete_mobile_section{
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 10px;
}

/* .arrow_back{
    position: absolute;
} */
