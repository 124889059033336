.jobpost-banner{
    background-image: url("images/jobpost.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height:100%;
  }
  #search-form {
    padding: 20px 5rem 2rem;
}
.buttons-boxes{
    display: flex;
    padding: 20px 5rem;
    margin-top: 1%;
    column-gap: 45px;
    
}
.buttons-boxes .button-black-active button{
    background: #121212;
    padding: 8px 30px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: none;
}
.buttons-boxes .button-border button{
    background:transparent;
    padding: 8px 30px;
    border-radius: 5px;
    color: #121212;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #121212;
}
.left-button{
    text-align: center;
    margin: 4% 0px;
    display: flex;
    justify-content: center;
    column-gap: 3px;

}
.left-icon{
    color: #A4A4A4;
    border: none;
    width: 50px;
    height: 50px;
    font-size: 20px;
}
.number-icon{
    display: flex;
    align-items: center;
    width: 245px;
    border: none;
    padding: 0px 20px;
    column-gap: 18px;
}
.number-icon span{
    border: none;
    font-size: 18px;
    font-weight: 500;
}
.right-icon{
    color: #121212;
    border: none;
    width: 50px;
    height: 50px;
    font-size: 20px;
}
.orange-text{
    color: #FD7E14;
    border-bottom: 3px solid #FD7E14!important;
}
.job-post-text{
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 20px 1rem;
    width: 100%;
    height: 100%;
    align-items: end;
}
.different-jobs-catogery{
    padding: 20px 5rem 5rem;
}
.profile-list{
    display: flex;
    align-items: center;
    column-gap: 25px;
}
.profile-name{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.profile-list i{
font-size: 22px;
color: white;
}
.profile-name h3{
    font-size: 20px;
    color: white;
}