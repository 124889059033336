* {
    margin: 0;
    padding: 0;
    font-family: Sans-serif;
}

body {
    background-color: #eee;

}

.icon:hover{
    color: orange;
}
.active-icon{
    color: orange;
}

/* .fas:hover{
    background-color: #E78341;
} */

nav {
    margin: 20px;
    position: relative;
}

ul {
    list-style: none;
    display: flex;
    align-items: center;
    display: contents;
}

.display-picture {
    margin-left: auto;
}
.fa-comment-dots {
    position: relative;
    font-size: 24px; /* Adjust the size as needed */
  }
  
  .chat-count {
    position: absolute;
    top: -5px; /* Adjust position as needed */
    right: -10px; /* Adjust position as needed */
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px; /* Adjust padding for size */
    font-size: 12px; /* Adjust font size */
    font-weight: bold;
  }
  

.display-picture img {
    width: 50px;
    border-radius: 50%;
    border: 2px solid #fff;
}

.display-picture img:hover {
    border: 2px solid #E78341;
}

.card {
    transition: 0.5s ease;
}

.card ul {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: #E78341;
    position: absolute;
    top: 4rem;
    right: 0rem;
    border-radius: 10px;
    padding: 10px 50px 10px 20px;
}

.card ul li {

    padding: 5px 0;
    color: #FFF;
    font-size: 14px;
}

.hidden {
    display: none;
}

a {
    text-decoration: none;
    color: #fff;
}
.cat_name{
    color: rgb(255, 94, 0) !important;
}
.sub_cat_name{
    font-weight: 600;
}
.not_sub{
    display: flex;
}
.not_sub:hover{
    color:#E78341;
    background-color: transparent;
}
