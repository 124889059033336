.join-link{
    margin-bottom: 15px;
}
.join-link p{
    font-size: 16px;
    font-weight: 500;
    color: #333333;
}
.zoom-app{
    margin-bottom: 15px;
}
.event_text p {
    font-size: 16px;
    font-weight: 400;
    color: #414141;
    width: 130%;
}
.view-details-button {
    width: 110%;
}
.local-job-listing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    width: 48%;
}
.joblisting-text span {
    color: #4A60A1;
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
}
.left-button.event-listing {
    text-align: center;
    margin-top: 28px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    column-gap: 3px;
}
.heading-section h6 {
    font-size: 22px;
    font-weight: 700;
    color: #121212;
}