.location_input_section{
    margin-top: 5px;
}

.location_ul_lists{
    list-style: none;
    margin-left: -30px !important;
    margin-top: 10px;
    
}

.location_ul_lists > li{
    padding: 7px 15px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 2px;
    background: var(--light-gray);
}

.required_field{
    color: var(--red);
}

#tooltip_text_wrapper{
    left: 30% !important;
    transform: translateX(-30%) !important;
}

.location_ul_lists {
    list-style: none;
    margin-left: -30px !important;
    margin-top: 10px;
    position: fixed;
    z-index: 300;
    width: 350px;
    /* position: relative; */
}

.current_location{
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;

}

.current_location span{
    font-size: 16px;
    margin: 0px 10px;
    cursor: pointer;
}

.current_location i{
    font-size: 16px;
    color: #F17D3A;
    cursor: pointer;
}

