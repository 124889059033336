.success_payment_modal{
    width: 600px !important;
}

.room_rents_modal_wrapper {
    max-width: 600px !important;
    border-radius: 10px;
    padding: 30px 40px 40px 40px !important;
    max-height: 688px !important;
}

.heading_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0 !important;
}

.heading_section>h3 {
    margin: 20px 0;


    /* Text lg / Semibold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 28px;
    /* identical to box height, or 156% */

    text-align: center;

    /* Gray / 900 */

    color: #101828;
}

.heading_section>p {
    max-width: 450px;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    /* or 143% */

    text-align: center;

    /* Gray / 600 */

    color: #475467;
}

/* .heading{
    color: var(--black);
}

.sub_heading{
    width: 400px;
    margin: 0 auto;
} */

.heading {
    font-size: 1.2rem;
    text-align: center;
}

.button_wrapper {
    align-items: end !important;
}

#submit_btn_wrapper {
    margin-top: 30px;
    display: block !important;
    width: 100% !important;
}

#submit_btn_wrapper>button {
    font-family: var(--Inter);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
}

#home_btn{
    background-color: #FD7E14 !important;
}

#back_button {
    font-family: var(--Inter);
        font-style: normal;
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 24px;
        color: var(--dark-blue) !important;
}



.thanks_text{
    font-size: 1.1rem !important;
}

.congratsLink{
    text-decoration: underline!important;
    color: rgb(45, 115, 214);
}
