
.password_input_wrapper{
    display: flex;
    align-items: center;
}

.password_input_wrapper > svg{
    margin-left: -30px !important;
    cursor: pointer;
}


#submit_btn{
    margin-top: 30px;
    display: block !important;
    width: 100% !important;
}

.button_wrapper{
    display: flex;
    gap: 20px;
    
}



.label{
    color: var(--dark-gray);
    font-size: 1rem;
    margin-bottom: 10px;
}