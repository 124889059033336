.joblisting_header{
    background-color: black;
    color: white;
    padding: 20px 5rem;
}
.top_header{
    align-items: center;
    justify-content: space-between;
    display: flex;
}
.address{
    padding-left: 0px;
  }
  .icons{
    display: flex;
    justify-content: space-between;
    width: 14%;
    align-items: center;
    padding: 0px;
  }
  .post_btn{
    background: #FD7E14;
    padding: 5px 25px;
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    border: none;
  }
  .joblisting_top_header{
    background-color: black;
  }
  .navbar_list{
    padding: 20px 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Logo_icon img{
    width: 14rem;
  }
  .menus{
    color:white;
    list-style: none;
  }
  .menus ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    column-gap: 60px;
    margin-bottom: 0;
}
.profile_list {
    display: flex;
    align-items: center;
    column-gap: 25px;
}
.profile_name{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.profile_list i{
font-size: 22px;
color: white;
}
.profile_name h3{
    font-size: 20px;
    color: white;
}
.navbar_list {
  padding: 20px 3rem;
}
.Logo_icon img {
width: 12rem;
}
.joblisting_header{
padding: 20px 3rem;
}
.menus ul {
column-gap: 24px;
}
.flters-lists-section{
padding: 20px 3rem;
}
.Detail_list_wrapper{
  padding: 20px 3rem;
  display: flex;
  justify-content: space-between;

  margin-top: 3%;
}
.Detail_list_wrapper_image{
  width: 60%;
}
.Detail_list_wrapper_image img{
  width: 100%;
}
.Detail_list_right_bar{
  width: 38%;
 
}
.Detail_list_right_bar1{
border: 1px solid #dbd1d1;
padding: 30px;
border-radius: 7px;
margin-bottom: 20px;
}
.Detail_list_apply{
border: 1px solid #dbd1d1;
padding: 30px;
border-radius: 7px;
}
.Detail_list_apply h2{
font-size: 24px;
color: #414141;
font-weight: 500;
}
.Detail_list_apply p{
font-size: 16px;
color: #3F3F3F;
font-weight:500;
}

.link_wrapper img{
width: 5%;
margin-right: 16px;
}
.save_btn{
text-align: center;


}
.save_btn button{
padding: 6px 50px;
color: #FD7E14;
border: 1px solid;
background-color: transparent;
border-radius: 8px;
}
.Detail_list_icon_bar{
  display: flex;
  justify-content: space-between;
  
}
.Detail_list_icon_bar h1{
  font-size: 42px;
  font-weight: 600;
  color: black;
  margin-bottom: 0px;
}
.Detail_list_icons_item{
  display: flex;
  column-gap: 16px;
  align-items: center;
}
.location_icons{
  display: flex;
  margin-top: 20px;
}
.location_icons p{
  font-size: 18px;
  color: #3F3F3F;
  font-weight: 400;
  margin-bottom: 0px;

}
.location_icons span{
  font-size: 18px;
  color: #005B99;
  font-weight: 400;
}
.location_icons i{
  font-size: 24px;
  color: #FD7E14;
  margin-right: 12px;
}
.call_us{
  display: flex;
  margin-top: 20px;
}
.call_us i{
  font-size: 24px;
  color: #FD7E14;
  margin-right: 12px;

}
.Detail_list_wrapper_round_image{
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.Detail_list_wrapper_round_image h4{
  font-size: 18px;
  margin-bottom: 0px;

}
.Detail_list_wrapper_round_image p{
  font-size: 16px;
  margin-bottom: 0px;

}
.Detail_list_wrapper_round_image img{
  margin-right: 12px;
}
.Detail_list_location_wrapper{
display: flex;
justify-content: space-between;
margin-top:5%;
width: 150%;
}
.Detail_list_text{
width: 60%;
}
.location_wrapper{
width: 38%;
border: 1px solid #cbcbcb;
padding: 25px;
border-radius: 8px;

}
.location_wrapper h2{
font-size: 24px;
font-weight: 600;
color: #3F3F3F;
margin-bottom: 14px;
}
.location_wrapper img{
width: 100%;
}
.location_wrapper div{
text-align: center;
margin-top: 30px;
}
.location_wrapper button{
font-size: 16px;
font-weight: 600;
background: #121212  ;
padding: 6px 30px;
color: white;
border-radius: 8px;
}
.Detail_list_text h3{
color: #3F3F3F;
font-size: 22px;
font-weight: 600;
}
.detail_box{
display: flex;
margin-bottom: 15px;
}
.tranlslate_image{
display: flex;
align-items: center;
margin-left: 20px;
}

.tranlslate_image p{
font-size: 16px;
font-weight: 400;
color: #3F3F3F;
margin-bottom: 0px;
margin-left: 10px;
}
.detail_box p{
font-size: 16px;
font-weight: 400;
color: #3F3F3F;
margin-bottom: 0px;
}
.detail_box span{
color: #4A60A1;
font-size: 16px;
font-weight: 600;
}
.address_text{
display: flex;
align-items: center;
margin-bottom: 15px;
}
.address_text p{
font-size: 16px;
font-weight: 600;
color: #3F3F3F;
margin-bottom: 0px;
}
.address_text span{
color: #949494  ;
text-transform: capitalize;
font-size: 16px;
}
.address_text img{
margin-right: 8px;
width: 3%;
}
.date_list p{
font-size: 16px;
font-weight: 700;
color: #3F3F3F;
}
.date_list span{
font-size: 16px;
font-weight: 500;
color: #3F3F3F;
}
.date_list{
border-bottom: 1px solid #dfdfdf;
padding-bottom: 3px;

}
.description_wrapper h2{
font-size: 24px;
font-weight: 600;
color: #121212;
text-transform: capitalize;
}
.description_wrapper p{
font-size: 16px;
font-weight: 400;
color: #3F3F3F;
}
.description_wrapper{
margin-top: 2%;
}
.deatil_list_card_wrapper{
padding: 20px 3rem;
}
.deatil_list_card_wrapper h2{
font-size: 24px;
font-weight: 500;
color: #414141;
}
.easy_btn{
  position: relative;
}
.easy_btn img{
  position: absolute;
  width: 15%;
  top: 8px;
  left: 8px;
}
.easy_btn button{
  background-color: #FD7E14;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 6px 25px 6px 38px;
  border: none;
  border-radius: 8px;
}
.button_box{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}
.ui_desoigner_skill img{
  width: 3%;
  margin-right: 8px;
}
.ui_desoigner_skill{
  display: flex;
  align-items: center;
}
.ui_desoigner_skill p{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}
.ui_desoigner_skill_lists ul{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
 padding-left: 0px;
 list-style: none;
}
.ui_desoigner_skill_lists1 ul{
  display: flex;
  justify-content: space-between;
  width: 94%;
  margin-top: 20px;
 padding-left: 0px;
 list-style: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1366px){
  .navbar_list {
    padding: 20px 3rem;
  }
.Logo_icon img {
  width: 12rem;
}
.joblisting_header{
  padding: 20px 3rem;
}
.menus ul {
  column-gap: 24px;
}
.flters-lists-section{
  padding: 20px 3rem;
}
.Detail_list_wrapper{
    padding: 20px 3rem;
    display: flex;
    justify-content: space-between;

    margin-top: 3%;
}
.Detail_list_wrapper_image{
    width: 60%;
}
.Detail_list_wrapper_image img{
    width: 100%;
}
.Detail_list_right_bar{
    width: 38%;
   
}
.Detail_list_right_bar1{
  border: 1px solid #dbd1d1;
  padding: 30px;
  border-radius: 7px;
  margin-bottom: 20px;
}
.Detail_list_apply{
  border: 1px solid #dbd1d1;
  padding: 30px;
  border-radius: 7px;
}
.Detail_list_apply h2{
  font-size: 24px;
  color: #414141;
  font-weight: 500;
}
.Detail_list_apply p{
  font-size: 16px;
  color: #3F3F3F;
  font-weight:500;
}

.link_wrapper img{
  width: 5%;
  margin-right: 16px;
}
.save_btn{
  text-align: center;


}
.save_btn button{
  padding: 6px 50px;
  color: #FD7E14;
  border: 1px solid;
  background-color: transparent;
  border-radius: 8px;
}
.Detail_list_icon_bar{
    display: flex;
    justify-content: space-between;
    
}
.Detail_list_icon_bar h1{
    font-size: 42px;
    font-weight: 600;
    color: black;
    margin-bottom: 0px;
}
.Detail_list_icons_item{
    display: flex;
    column-gap: 16px;
    align-items: center;
}
.location_icons{
    display: flex;
    margin-top: 20px;
}
.location_icons p{
    font-size: 18px;
    color: #3F3F3F;
    font-weight: 400;
    margin-bottom: 0px;

}
.location_icons span{
    font-size: 18px;
    color: #005B99;
    font-weight: 400;
}
.location_icons i{
    font-size: 24px;
    color: #FD7E14;
    margin-right: 12px;
}
.call_us{
    display: flex;
    margin-top: 20px;
}
.call_us i{
    font-size: 24px;
    color: #FD7E14;
    margin-right: 12px;

}
.Detail_list_wrapper_round_image{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.Detail_list_wrapper_round_image h4{
    font-size: 18px;
    margin-bottom: 0px;

}
.Detail_list_wrapper_round_image p{
    font-size: 16px;
    margin-bottom: 0px;

}
.Detail_list_wrapper_round_image img{
    margin-right: 12px;
}
.Detail_list_location_wrapper{
  display: flex;
  justify-content: space-between;
  margin-top:5%;
  width: 150%;
}
.Detail_list_text{
  width: 60%;
}
.location_wrapper{
  width: 38%;
  border: 1px solid #cbcbcb;
  padding: 25px;
  border-radius: 8px;

}
.location_wrapper h2{
  font-size: 24px;
  font-weight: 600;
  color: #3F3F3F;
  margin-bottom: 14px;
}
.location_wrapper img{
  width: 100%;
}
.location_wrapper div{
  text-align: center;
  margin-top: 30px;
}
.location_wrapper button{
  font-size: 16px;
  font-weight: 600;
  background: #121212  ;
  padding: 6px 30px;
  color: white;
  border-radius: 8px;
}
.Detail_list_text h3{
  color: #3F3F3F;
  font-size: 22px;
  font-weight: 600;
}
.detail_box{
  display: flex;
  margin-bottom: 15px;
}
.tranlslate_image{
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.tranlslate_image p{
  font-size: 16px;
  font-weight: 400;
  color: #3F3F3F;
  margin-bottom: 0px;
  margin-left: 10px;
}
.detail_box p{
  font-size: 16px;
  font-weight: 400;
  color: #3F3F3F;
  margin-bottom: 0px;
}
.detail_box span{
  color: #4A60A1;
  font-size: 16px;
  font-weight: 600;
}
.address_text{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.address_text p{
  font-size: 16px;
  font-weight: 600;
  color: #3F3F3F;
  margin-bottom: 0px;
}
.address_text span{
  color: #949494  ;
  text-transform: capitalize;
  font-size: 16px;
}
.address_text img{
  margin-right: 8px;
  width: 3%;
}
.date_list p{
  font-size: 16px;
  font-weight: 700;
  color: #3F3F3F;
}
.date_list span{
  font-size: 16px;
  font-weight: 500;
  color: #3F3F3F;
}
.date_list{
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 3px;

}
.description_wrapper h2{
  font-size: 24px;
  font-weight: 600;
  color: #121212;
  text-transform: capitalize;
}
.description_wrapper p{
  font-size: 16px;
  font-weight: 400;
  color: #3F3F3F;
}
.description_wrapper{
  margin-top: 2%;
}
.deatil_list_card_wrapper{
  padding: 20px 3rem;
}
.deatil_list_card_wrapper h2{
  font-size: 24px;
  font-weight: 500;
  color: #414141;
}
.easy_btn{
    position: relative;
}
.easy_btn img{
    position: absolute;
    width: 15%;
    top: 8px;
    left: 8px;
}
.easy_btn button{
    background-color: #FD7E14;
    color: white;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 6px 25px 6px 38px;
    border: none;
    border-radius: 8px;
}
.button_box{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
}
.ui_desoigner_skill img{
    width: 3%;
    margin-right: 8px;
}
.ui_desoigner_skill{
    display: flex;
    align-items: center;
}
.ui_desoigner_skill p{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}
.ui_desoigner_skill_lists ul{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
   padding-left: 0px;
   list-style: none;
}
.ui_desoigner_skill_lists1 ul{
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin-top: 20px;
   padding-left: 0px;
   list-style: none;
}
}