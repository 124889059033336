.verify_modal_wrapper{
    max-width: 520px !important;
    border-radius: 10px;
    padding: 30px 40px  50px 40px !important;
    max-height: 600px !important;
}


#phone_number_section{
    display: grid;
    grid-template-columns: 20% 80%;
}

.country_code_inpput_field{
    border-right: none;
}

.country_code_phone_number_wrapper{
    display: grid;
    grid-template-columns: 15% 85%;
}

.country_code_phone_number_wrapper > input,
.country_code_phone_number_wrapper > select{
    height: 40px !important;
    height: 50px !important;
}

.country_code_phone_number_wrapper > input{
    padding: 5px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none !important;
}

.country_code_phone_number_wrapper > select{
    padding: 5px 10px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin-top: 0px !important;
}

.forgrt_password_text{
    display: flex;
    justify-content: end;
    margin-top: 5px;
    cursor: pointer;
}

#submit_btn{
    margin-top: 30px;
}

.heading{
    font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        margin: 0 auto;
}

.verify_modal_wrapper{
    max-width: 500px !important;
}

.login_text{
        font-family: 'Brother 1816';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #8E8E94;
        text-align: center;
        margin-top: 10px;
}

.login_text > span{
    cursor: pointer;
    color: rgb(114, 114, 224);
    padding-left: 5px;
}

.horizontal_line_wrapper{
    display: flex;
    justify-content: center;
        align-items: center;
}

.horizontal_line_wrapper > span {
    margin: 0 10px;
}

.horizontal_line{
    border-bottom: 1px solid #E4E7EC;;
    width: 100%;
}

.google_login_btn,
.apple_login_btn
{
     width: 100%;
    padding: 10px;
    border: none !important;
    margin-top: 20px !important;
    background-color: var(--white) !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
    border: 1px solid #D0D5DD !important;
}

.apple_login_btn{
    margin-top: -50px !important;
    font-size: 1.2rem !important;

}

.apple_login_btn > svg,
.google_login_btn>svg {
    margin-right: 10px;
    
}



/* jahsgdahgsfd */

.password_input_wrapper{
    display: flex;
    align-items: center;
    margin-top:  0 !important;
}

.password_input_wrapper > svg{
    margin-left: -30px !important;
    cursor: pointer;
}

#submit_btn{
    margin-top: 30px;
}

